import { BrowserModule, Title } from "@angular/platform-browser";
import { HttpLoaderFactory } from "./app.translate";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { DatePipe } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { DeviceDetectorModule } from "ngx-device-detector";
import { KeyFilterModule } from "primeng/keyfilter";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import { PanelModule } from "primeng/panel";
import { InputSwitchModule } from "primeng/inputswitch";
import { socket } from "src/environments/environment";
import { ConfigService } from "./CoreWager/services/configService";
import { DataService } from "./CoreWager/services/DataService";
import { MiscService } from "./CoreWager/services/misc.service";
import { LinesService } from "./CoreWager/services";
import { AuthServices } from "./CoreWager/services/auth.service";
import { PlayerService } from "./CoreWager/services/player.service";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { CookieService } from "ngx-cookie-service";

import { AppComponent } from "./app.component";
import { routing } from "./app.routing";

import { SharedWagerClass } from "./SharedClasses/WagerClass";

import { CarouselModule } from "primeng/carousel";

import { Http, HttpModule } from "@angular/http";
import { HttpClientModule, HttpClient } from "@angular/common/http";

import { DialogModule } from "primeng/dialog";
import { ProgressBarModule } from "primeng/progressbar";
import { GalleriaModule } from "primeng/galleria";
import { FileUploadModule } from "primeng/fileupload";
import { CalendarModule } from "primeng/calendar";
import { DropdownModule } from "primeng/dropdown";
import { InputTextareaModule } from "primeng/inputtextarea";
import { OrderListModule } from "primeng/orderlist";
import { PickListModule } from "primeng/picklist";
import { BlockUIModule } from "primeng/blockui";
import { NgxLoadingModule } from "ngx-loading";
import { TableModule } from "primeng/table";
import { CheckboxModule } from "primeng/checkbox";
import { CardModule } from "primeng/card";
import { TabMenuModule } from "primeng/tabmenu";
import { ListboxModule } from "primeng/listbox";
import { RadioButtonModule } from "primeng/radiobutton";
import { ExtLoginComponent } from "./FrontEnd/ext-login/ext-login.component";

import { LoginModule } from "./CoreWager/AccessLogin/login/login.module";

import { SocketIoModule, SocketIoConfig } from "ngx-socket-io";

// import { SlipAndGetPipe } from './Pipes/slip-and-get.pipe';

const config: SocketIoConfig = { url: socket.socketUrl, options: {} };

@NgModule({
  declarations: [
    AppComponent,
    ExtLoginComponent,

    // SlipAndGetPipe,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    routing,
    AccordionModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    DialogModule,
    KeyFilterModule,
    DeviceDetectorModule.forRoot(),
    ProgressBarModule,
    GalleriaModule,
    FileUploadModule,
    CarouselModule,
    PanelModule,
    InputSwitchModule,
    CalendarModule,
    DropdownModule,
    InputTextareaModule,
    OrderListModule,
    PickListModule,
    BlockUIModule,
    NgxLoadingModule.forRoot({
      primaryColour: "#62A1E9",
      secondaryColour: "#62A1E9",
      tertiaryColour: "#62A1E9",
    }),
    TableModule,
    CheckboxModule,
    CardModule,
    TabMenuModule,
    ListboxModule,
    RadioButtonModule,
    LoginModule,
    SocketIoModule.forRoot(config),
  ],
  exports: [TranslateModule],
  providers: [
    AuthServices,
    TranslateService,
    LinesService,
    SharedWagerClass,
    PlayerService,
    ConfigService,
    DataService,
    DatePipe,
    MiscService,
    CookieService,
    Title,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
