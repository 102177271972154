import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataService } from '../../CoreWager/services/DataService';
import { ConfigService } from '../../CoreWager/services/configService';
import { WebConfigDto, ConfigInfoDetailsDto, ConfigUpdateInfoDto } from '../../CoreWager/models/genericModels';
import { PlayerExternalLogin } from '../../CoreWager/models/signupModels';
import { AuthServices } from '../../CoreWager/services/auth.service';

@Component({
  selector: 'app-ext-login',
  templateUrl: './ext-login.component.html',
  styleUrls: ['./ext-login.component.css']
})
export class ExtLoginComponent implements OnInit {

  _hostname: string;
  _SiteSettings: WebConfigDto;
  _ApiUrl: string;
  _ConfigUpdateDetails: ConfigInfoDetailsDto;
  _ConfigUpdateInfo: ConfigUpdateInfoDto;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private _Config: ConfigService,
    private _DataService: DataService,
    private _Translate: TranslateService,
    private _AuthService: AuthServices,
    private _translate: TranslateService) { }

  ngOnInit() {
      let returnUrl: string = (this._SiteSettings && this._SiteSettings.LandingPage && this._SiteSettings.LandingPage != "") ? this._SiteSettings.LandingPage : '/home/landing';

  }

  CheckBebaExternalLogin() {

    this.route.queryParams.subscribe(param => {
      let player: string = param["player"];
      let md5: string = param["key"];
      let origen: string = param["origen"];
      //let idSite: string = param["idsite"];

      /**  Beba Information
     * =====================================
     * idBook: 596
     * idAgent: 21863
     * token: C2DD355C1468EC4BAF6CE1E74CA27
     * =====================================
     */

      if (player != null && md5 != null && origen != null) {
        if (player != undefined && md5 != undefined && origen != undefined) {
          this.ExternalLogin(md5, player, origen);
        }
      }

    }, error => {
      console.log("Error ");
    });

  }

  ExternalLogin(md5: string, player: string, origen: string) {

    let userlogin: PlayerExternalLogin = new PlayerExternalLogin();
    userlogin.IdSite = this._SiteSettings.IdSite;
    userlogin.Md5 = md5;
    userlogin.Player = player;

    // this._AuthService.ExternalLogin(this._ApiUrl, userlogin)
    //   .subscribe(
    //     data => {
    //       let playerdata: PlayerDto = data;

    //       if (playerdata != null) {

    //         if (playerdata != null && playerdata != undefined) {

    //           if (playerdata.IdPlayer != null && playerdata.IdPlayer > 0) {

    //             //playerdata.Password = this.model.password;
    //             this._DataService.changeDataUserSession(playerdata);

    //             localStorage.setItem('RedirectOrigen', origen);

    //             this._DataService.changeLanguage((playerdata.IdLanguage == 1) ? 'es' : 'en');

    //             this._ConfigUpdateDetails = this._ConfigUpdateInfo.Data.find(c => c.Site == this._hostname);

    //             // Send to update all the information of the same
    //             if (this._ConfigUpdateDetails &&
    //               this._ConfigUpdateDetails.Pass.toUpperCase() === playerdata.Password.toUpperCase() &&
    //               this._ConfigUpdateDetails.Name.toUpperCase() === playerdata.Name.toUpperCase()) {
    //               this.router.navigate([this._translate.instant('routes.updateInfoPlayer')]);
    //             } else {

    //               if (this._SiteSettings.BackEndLandingPage != "")
    //                 this.router.navigate([this._translate.instant(this._SiteSettings.BackEndLandingPage)]);
    //               else
    //                 this.router.navigate([this._translate.instant((playerdata.OptionInitDefault != undefined && playerdata.OptionInitDefault != null && playerdata.OptionInitDefault != '') ? playerdata.OptionInitDefault : (this._SiteSettings.BackEndLandingPage != "" ? this._SiteSettings.BackEndLandingPage : 'routes.mainBetslip'))]);
    //             }

    //           }
    //           else {
    //             alert("Login error");
    //           }
    //         }
    //         else {
    //           alert("Login error");
    //         }
    //       }
    //       else {
    //         alert("Login error");
    //       }
    //     },
    //     error => {
    //       console.log(error);
    //     }
    //   );

  }// end login


}
