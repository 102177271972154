
export class SiteByPlayerForCountryCurrency {
    id: number;
    idSignUpColumn: number;
    name: string;
    idSignUpPlayer: number;
    mandatoryField: boolean;
    isActive: boolean;
    value: string;
    pathLanguage: string;
};

export class PlayerExternalLogin{
    Player: string;
    Md5: string;
    IdSite: string;
}

export class PlayerExternalLoginV2 {
  Player: string;
  Sha256: string;
  IdSite: string;
}

export class RequestSiteByPlayerForCountryCurrency {
    siteTag: string;
    idCountry: string;
    idCurrency: number;
};

export class Title {
    title: string;
};

export class Country {
    idCountry: string;
    country: string;
};

export class State {
    idISOCountry: string;
    idISOState: string;
    state: string;
};

export class Currency {
    idCurrency: number;
    currency: string;
    description: string;
};

export class CheckEmail {
    siteDomain: number;
    email: string;
};

export class CheckNameAddress {
    firstName: string;
    lastName: string;
    address: string;
    siteDomain: string;
};

export class GetParamsFromUrl {
    ipAddress: string;
    country: string;
    siteDomain: string;
};

export class CheckNamePhone {
    firstName: string;
    lastName: string;
    phone: string;
    siteDomain: string;
};

export class Player {
    password: string;
    name: string;
    lastName: string;
    lastName2: string;
    title: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    phone: string;
    fax: string;
    email: string;
    dateOfBirth: Date;
    signUpIP: string;
    secQuestion: string;
    secAnswer: string;
    idUserSocialNetwork: string;
    idSignupPlayersType: number;
    agentSales: string;
    idLineType: number;
    codeCountryPhone: number;
    idPromotion: number;
    latitude: string;
    longitude: string;
    region: string;
    isAnonymous: boolean;
};

export class FacebookUser {
    birthday: Date;
    email: string;
    first_name: string;
    gender: string;
    id: string;
    last_name: string;
};

export class GetToken {
    token: string;
};

export class ValidatePlayerInsert {
    firstName: string;
    lastName: string;
    address: string;
    siteDomain: string;
    phone: string;
    email: string;
    idLanguage: string;
    birthdate: Date;
}

export class PlayerInfo {
    idPlayer: number;
    master: boolean;
    status: string;
    onlineAccess: boolean;
    eposPlayer: boolean;
    creditLimit: number;
    chartPercent: number;
    masterChart: number;
    idLineType: number;
    idCurrency: number;
    idOffice: number;
    idGrouping: number;
    idPlayerRate: number;
    playerRateName: string;
    idBook: number;
    idProfile: number;
    idProfileLimits: number;
    idAgent: number;
    maxWager: number;
    minWager: number;
    onlineMaxWager: number;
    onlineMinWager: number;
    holdBets: boolean;
    holdDelay: number;
    enableSports: boolean;
    enableCasino: boolean;
    enableHorses: boolean;
    enableCards: boolean;
    showInTicker: boolean;
    lineStyle: string;
    nhlLine: string;
    mlbLine: string;
    pitcherDefault: number;
    scheduleStyle: string;
    idTimeZone: number;
    idLanguage: number;
    player: string;
    country: string;
    lastModificationUser: number;
    currency: string;
};

export class WelcomePlayerParams {
    title: string;
    player: string;
    password: string;
    name: string;
    lastName: string;
    idLanguage: number;
};

export class infoEmail {
    message: String;
    status: number;
};

export class LocationService {
    ip: string;
    city: string;
    region: string;
    country: string;
    state: string;
    postal: string;
    org: string;
    latitude: string;
    longitude: string;
};

export class SendHash {
    hash: string;
};

export class GetSite {
    siteDomain: string;
    player: string;
    password: string;
};

export class GetBook {
    bookDescription: string;
};

export class playerIdentificationInsert {
    idPlayer: number;
    idNumber: string;
    passport: string;
    rfc: string;
    curp: string;
};

export class insertCsiPlayer {
    accountNumber: string;
    book: string;
    firstName: string;
    lastName: string;
    ipAddress: string;
    address: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    homePhone: string;
    workPhone: string;
    mobilePhone: string;
    fax: string;
    textMessages: boolean;
    email: string;
    hear: string;
    mother: string;
    ssn: string;
    remarks: string;
    referralAccount: string;
    bankingInfo: string;
    sports: boolean;
    casino: boolean;
    horses: boolean;
    poker: boolean;
    userId: number;
    password: string;
    promoCode: string;
    claimedBy: number;
}

export class socialNetworksBySiteTag {
    idSocialNetworkTokenBySiteTag: number;
    idSignupPlayerType: number;
    socialNetworkName: string;
    token: string;
    siteTag: {
        idSiteTag: number;
        description: string;
        siteDomain: string;
    };
}

export class affiliateCookie {
    affiliateCode: string;
    bannerCode: string;
    campaignCode: string;
}

export class termsConditions {
    id: number;
    idSignupPlayers: number;
    pathLanguage: string;
    isActive: boolean;
    lastModifiedBy: number;
}

export class UrlImages {
    urls: string;
}

export class ShowMessageText {
    message: string;
    idPartMessage: number;
};


export class confirmEmail {
    status: string;
    account: string;
    phone: string;
    email: string;
    siteDomain: string;
    language: string;
}

export class LocationDto {
    city: string;
    country: string;
    hostname: string;
    ip: string;
    loc: string;
    org: string;
    postal: string;
    region: string;
    country_code: string;
    region_code: string;
    error: string;
    latitude?: string;
    longitude?: string;

}

export class ValidInputsDto {
    ValidBirth: boolean;
    EmailsMatch: boolean;
    PasswordMatch: boolean;
    AccessByGoogle: boolean;
    AccessBirthDate: boolean;
    AccessByFacebook: boolean;
    AccessMarketing: boolean;
    AccessMainInterests: boolean;
    AccessTermsAndConditions: boolean;
    AccessAnonymousAccount: boolean;
}

export class QuickSignUpDto
{
    IP: string;
    IdSite: number;
    SiteDomain: string;
    Language: string;
    Title: string;
    IsAnonymous: boolean;
    VerifyByPhone: boolean;
    Player: string;

    IdPlayer: number;
    FirstName: string;
    LastName: string;
    LastName2: string;
    Email: string;
    EmailConfirmation: string;
    Address: string;
    Address2: string;
    PhoneNumber: string;
    AttachPictureId: string;
    GovernmentId: string;

    Currency: Currency;
    Country: Country;
    CountryCode: string;
    State: State;

    LineType: LineTypeModel;

    City: string;
    Password: string;
    PasswordConfirmation: string;

    Fax: string;
    Zip: string;
    DateBirth: Date;

    DayBirth: GenericDataDto;
    MonthBirth: GenericDataDto;
    YearBirth:GenericDataDto;

    SecQuestion: string;
    SecAnswer: string;
    Rfc: string;
    Curp: string;
    Passport: string;

    // Marketing
    PromotionalCode: string;
    Hear: string;
    ReferralAccount: string;

    // Interests
    Sportsbook: boolean;
    Casino: boolean;
    Racebook: boolean;
    Poker: boolean;

    Cookies: CookiesDto;

    IdPlayerType: number;
    IdUserSocialNetwork: string;

    IdAgent: number;
    WithAccountVerification: boolean;
}

export class CookiesDto
{
    // Affiliates
    AffiliateCode: string;
    BannerCode: string;
    CampaignCode: string;
    Mpo: string;
}

export class QuickSignUpResult
{
    Id: number;
    MsjError: string;
    Player: string;
}

export class QuickSignUpNotificationDto
{
    IdPlayer: number;
    IdSite: number;
    CodeCountry: string;
}


export class QuickSignUpVerifiedDto
{
    IdPlayer: number;
    Code: string;
    VerifyByLink: boolean;
}

export class QuickSignUpVerifiedResultDto
{
    result: string;
    Name: string;
    Player: string;
}

export class LineTypeModel
{
    Id: number;
    Name: string;
    Description: string;
}

export class GetLineTypePromotionBySiteCountry
{
    idPromotion: number;
    promoName: string;
}

export class GenericDataDto {
    Id: number;
    Name: string;
    Value: string;
}

export class SelectGoogleRecaptchaBySiteRquestDto {
    GoogleRecaptchaVersionId?:  number;
    SiteId?:  number;
    SiteKey: string;
    SecretKey: string;
    IsActive?: boolean;
    LastModifiedBy?: number;
  }

  export class GoogleRecaptchaResponse {
    success: boolean;
    challenge_ts: string
    hostname: string
    score: number;
    action: string
  }
  /*Bronto Service*/
export class PlayerInformation{
    PlayerAccount: string;
    SiteId: number;
    WantsEmails: boolean;
  }
  export class BrontoResult {
    HasErrors: boolean;
    ErrorIndicies: number[];
    Items: BrontoResultItem[];
  
  }
  export class BrontoResultItem {
    Id: number;
    IsNew:boolean; 
    IsError:boolean; 
    ErrorCode:number;
    ErrorString:string;
  }
