import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";

import {
  OpenBetItem,
  TimeZoneItem,
  RequestTimeZones,
  RequestLiveToken,
  RequestUpdatePlayerPitcher,
  RequestSiteVarCashierDto,
  SiteVarCashierDto,
  CountryDto,
  StateDto,
  RequestUpdatePlayerOptionInitDefault,
  ChatSiteModel,
  ActionPlayersModel,
  CasinoTomHornModel,
  CasinosModel,
  SiteMessagesDto,
  CasinoInfoModel,
  RequestBanksProvidersDto,
  ReponseBanksProvidersDto,
  RequestEndorphinaDto,
  ResponseEndorphinaDto,
  RequestSoccerUltraDto,
  PlayForFreeResponse,
  PlayForFreeRequest,
  InitProductResponse as InitProductResponseDto,
  InitProductRequest as InitProductRequestDto,
  VirtualSportTeamDto,
  ScheduleGameRequestDto,
  ScheduleGameResponseDto,
  VirtualSportTeamRequestDto,
  CashierTransDTO,
  CashierGetTransDTO,
} from "../models/genericModels";
import {
  PlayerTransferDto,
  RequestPlayerInfo,
  RequestPlayerInfoDto,
  RequestSha256,
  PlayerInfoDetailsDto,
  RequestState,
  RequestPasswordDto,
  LeagueOrderModel,
  LeagueModel,
  RequestCodePasswordDto,
  PlayerForgotPassDto,
  PlayerResetPassDto,
  PlayerRedeemPointsDto,
  AccessSite,
  CashierTransRequestDTO,
  CashierReportTransDTO,
} from "../models/user";
import { RequestPlayerHistory } from "../models/genericModels";
import { PlayerHistoryDto } from "../models/genericModels";
import {
  AuthenticationDto,
  FillOpenBetsDTO,
  WagerTypeModel,
} from "../models/wagerModels";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class PlayerService {
  constructor(private httpClient: HttpClient, private router: Router) {}

  // GetLiveWageringCount(apiUrl: string): Observable<number> {
  //     let _apimethod = "Guest/LiveWageringCount";
  //     return this._http.get(apiUrl + _apimethod)
  //         .map((response: Response) => <number>response.json());

  // }

  // GetPlayerStatistics(apiUrl: string, f: AuthenticationDto): Observable<PlayerStatiscticsModel> {

  //     let requestValues = {
  //         "playerAccount": ""
  //     }

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/GetPlayerStatistics";

  //     return this._http.post(apiUrl + _apimethod, requestValues, this._options)
  //         .map((response: Response) => <PlayerStatiscticsModel>response.json());
  //     //.do(x => console.log(x))
  // }

  GetPlayerOpenBets(
    t: RequestPlayerInfo,
    f: AuthenticationDto
  ): Observable<OpenBetItem[]> {
    let a: OpenBetItem[];
    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/GetOpenBets";

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: OpenBetItem[]) => response),
      catchError(this.handleError<OpenBetItem[]>("GetPlayerOpenBets", a))
    );
  }

  GetPlayerInfo(
    t: RequestPlayerInfo,
    f: AuthenticationDto
  ): Observable<PlayerTransferDto> {
    const a: PlayerTransferDto = new PlayerTransferDto();
    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/GetPlayerInfo";

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: PlayerTransferDto) => response),
      catchError(this.handleError<PlayerTransferDto>("GetPlayerInfo", a))
    );
  } // end method

  GetTimeZones(f: AuthenticationDto): Observable<TimeZoneItem[]> {
    let a: TimeZoneItem[];
    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/GetTimeZones";

    return this.httpClient.post(apiUrl, null, options).pipe(
      map((response: TimeZoneItem[]) => response),
      catchError(this.handleError<TimeZoneItem[]>("GetTimeZones", a))
    );
  }

  UpdateLanguage(
    t: RequestPlayerInfoDto,
    f: AuthenticationDto
  ): Observable<PlayerTransferDto> {
    const a: PlayerTransferDto = new PlayerTransferDto();

    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/UpdatePlayerLanguage";

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: PlayerTransferDto) => response),
      catchError(this.handleError<PlayerTransferDto>("UpdateLanguage", a))
    );
  }

  GetPlayerInfoDetails(
    t: RequestPlayerInfo,
    f: AuthenticationDto
  ): Observable<PlayerInfoDetailsDto> {
    const a: PlayerInfoDetailsDto = new PlayerInfoDetailsDto();

    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/GetPlayerInfoDetails";

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: PlayerInfoDetailsDto) => response),
      catchError(
        this.handleError<PlayerInfoDetailsDto>("GetPlayerInfoDetails", a)
      )
    );
  }

  UpdatePlayerInfoDetails(
    t: PlayerInfoDetailsDto,
    pUpdateGenericAccount: boolean,
    f: AuthenticationDto
  ): Observable<string> {
    let a: string;

    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl =
      environment.apiUrl + pUpdateGenericAccount
        ? "Player/UpdatePlayerInfoDetailsGenericAccount"
        : "Player/UpdatePlayerInfoDetails";

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: string) => response),
      catchError(this.handleError<string>("UpdatePlayerInfoDetails", a))
    );
  }

  // SendEmailChangePass(apiUrl: string, IdSite: number, f: AuthenticationDto): Observable<string> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/SendEmailChangePass?IdSite=" + IdSite;

  //     return this._http.get(apiUrl + _apimethod, this._options)
  //         .map((response: Response) => <string>response.json());
  // }

  // VerifiedHashResetPassword(apiUrl: string, t: RequestCodePasswordDto): Observable<string> {

  //     let _apimethod: string = "Guest/VerifiedHashResetPassword";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <string>response.json());
  // }

  // VerifiedCodePass(apiUrl: string, t: RequestCodePasswordDto, f: AuthenticationDto, IsGuest: boolean): Observable<string> {

  //     let _apimethod: string = "";

  //     if (IsGuest) {
  //         _apimethod = "Guest/VerifiedCodePass";
  //     }
  //     else {
  //         let encode = this.GetEncodeToken(f);
  //         this._options.headers.set('Authorization', 'Token ' + encode);
  //         _apimethod = "Player/VerifiedCodePass";
  //     }

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <string>response.json());
  // }

  // ResetPassword(apiUrl: string, t: PlayerResetPassDto): Observable<string> {

  //     let _apimethod = "Guest/ResetPassword";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <string>response.json());
  // }

  // GetAccessSite(apiUrl: string, IdSite: string): Observable<AccessSite> {

  //     let _apimethod: string = "Guest/GetAccessSite?IdSite=" + IdSite;

  //     return this._http.get(apiUrl + _apimethod, this._options)
  //         .map((response: Response) => <AccessSite>response.json())
  //         .catch(this.handleError);

  // } //end function

  GetWagerType(): Observable<WagerTypeModel[]> {
    let a: WagerTypeModel[];
    const apiUrl =
      environment.apiUrl + "Guest/GetWagerType?IdSite=" + environment.idSite;

    return this.httpClient.get(apiUrl).pipe(
      map((response: WagerTypeModel[]) => response),
      catchError((error) => {
        return of(a as WagerTypeModel[]);
      })
    );
  } // end function

  // UpdatePassword(apiUrl: string, t: RequestPasswordDto, f: AuthenticationDto): Observable<string> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/UpdatePassword";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <string>response.json());
  // }

  // GetCountries(apiUrl: string, f: AuthenticationDto): Observable<CountryDto[]> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/GetCountries";

  //     return this._http.post(apiUrl + _apimethod, this._options)
  //         .map((response: Response) => <CountryDto[]>response.json());
  // }

  // GetStates(apiUrl: string, t: RequestState, f: AuthenticationDto): Observable<StateDto[]> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/GetStates";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <StateDto[]>response.json());
  // }

  // UpdateLineStyle(apiUrl: string, t: RequestPlayerInfoDto, f: AuthenticationDto): Observable<PlayerTransferDto> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/UpdatePlayerLineStyle";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <PlayerTransferDto>response.json());
  // }

  // UpdateTimeZone(apiUrl: string, t: RequestPlayerInfoDto, f: AuthenticationDto): Observable<PlayerTransferDto> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/UpdatePlayerTimeZone";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <PlayerTransferDto>response.json());
  // }

  // UpdatePlayerPitcherDefault(apiUrl: string, t: RequestUpdatePlayerPitcher, f: AuthenticationDto): Observable<any> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/UpdatePlayerPitcherDefault";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <any>response.json());
  // }

  // UpdatePlayerOptionInitDefault(apiUrl: string, t: RequestUpdatePlayerOptionInitDefault, f: AuthenticationDto): Observable<any> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/UpdatePlayerOptionInitDefault";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <any>response.json());
  // }

  // GetWord(apiUrl: string, t: RequestSha256, f: AuthenticationDto): Observable<string> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/GenerateSha256";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <string>response.json());
  // }

  // GetLiveToken(apiUrl: string, t: RequestLiveToken, f: AuthenticationDto): Observable<string> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/GetLiveToken";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <string>response.json());
  // }

  GetPlayerHistory(
    t: RequestPlayerHistory,
    f: AuthenticationDto
  ): Observable<PlayerHistoryDto> {
    const a: PlayerHistoryDto = new PlayerHistoryDto();
    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/GetPlayerHistory";

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: PlayerHistoryDto) => response),
      catchError(this.handleError<PlayerHistoryDto>("GetPlayerHistory", a))
    );
  } // end method

  GetPlayerFillOpenBets(
    t: RequestPlayerInfo,
    f: AuthenticationDto
  ): Observable<FillOpenBetsDTO> {
    const a: FillOpenBetsDTO = new FillOpenBetsDTO();
    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/GetFillOpen";

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: FillOpenBetsDTO) => response),
      catchError(this.handleError<FillOpenBetsDTO>("GetPlayerFillOpenBets", a))
    );
  }

  // GetSiteCashierVar(apiUrl: string, t: RequestSiteVarCashierDto, f: AuthenticationDto): Observable<SiteVarCashierDto> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Cashier/GetSiteCashierVar";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <SiteVarCashierDto>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetChatSite(apiUrl: string, hostName: string): Observable<ChatSiteModel> {

  //     let _apimethod: string = "Guest/GetChatSite?hostname=" + hostName;

  //     return this._http.get(apiUrl + _apimethod, this._options)
  //         .map((response: Response) => <ChatSiteModel>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetActionPlayerBySite(apiUrl: string, t: ActionByPlayerDto, f: AuthenticationDto, IsGuest: boolean): Observable<ActionPlayersModel> {

  //     let _apimethod: string = "";

  //     if (IsGuest) {
  //         _apimethod = "Guest/GetActionPlayerBySite";
  //     }
  //     else {
  //         let encode = btoa(f.AccountName + ":" + f.Password + ":" + "GetActiveLeagues");
  //         this._options.headers.set('Authorization', 'Token ' + encode);
  //         _apimethod = "Player/GetActionPlayerBySite";
  //     }

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <ActionPlayersModel>response.json())
  //         .catch(this.handleError);

  // } //end function

  // PlayerForgotPassword(apiUrl: string, t: PlayerForgotPassDto): Observable<string> {

  //     let _apimethod = "Guest/PlayerForgotPassword";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <string>response.json());
  // } //end function

  GetCurrentPoints(f: AuthenticationDto): Observable<number> {
    let a: number;
    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/GetCurrentPoints";

    return this.httpClient.get(apiUrl, options).pipe(
      map((response: number) => response),
      catchError(this.handleError<number>("GetCurrentPoints", a))
    );
  }

  // RedeemPlayerPoints(apiUrl: string, t: PlayerRedeemPointsDto, f: AuthenticationDto): Observable<string> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/RedeemPlayerPoints";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <string>response.json());
  // }

  // GetPaymentMethodsCurrency(apiUrl: string, t: RequestSiteVarCashierDto, f: AuthenticationDto): Observable<number> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Cashier/GetPaymentMethodsCurrency";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <number>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetPlayerCountry(apiUrl: string, t: RequestSiteVarCashierDto, f: AuthenticationDto): Observable<string> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Cashier/GetPlayerCountry";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <string>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetCashierType(username: string): Observable<string> {

  //     return this._http.get("//csi.corporaciondpt.com/rest/getAccountCategory.php?account=" + username)
  //         .map((response: Response) => <string>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetAccessCasinoTomHorn(apiUrl: string, f: AuthenticationDto): Observable<CasinoTomHornModel> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetAccessCasinoTomHorn";

  //     return this._http.post(apiUrl + _apimethod, null, this._options)
  //         .map((response: Response) => <CasinoTomHornModel>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetAccessCasinos(apiUrl: string, CasinoId: number, TabId: number, f: AuthenticationDto): Observable<CasinosModel> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetAccessCasinos?TabId=" + TabId + "&CasinoId=" + CasinoId;

  //     return this._http.post(apiUrl + _apimethod, null, this._options)
  //         .map((response: Response) => <CasinosModel>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetAccessCasinosV2(apiUrl: string, CasinoId: number, TabId: number, deviceTypeId: number, f: AuthenticationDto): Observable<CasinosModel> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetAccessCasinosV2?TabId=" + TabId + "&CasinoId=" + CasinoId + "&deviceTypeId=" + deviceTypeId;

  //     return this._http.post(apiUrl + _apimethod, null, this._options)
  //         .map((response: Response) => <CasinosModel>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetTokenCasino(apiUrl: string, f: AuthenticationDto): Observable<string> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetTokenCasino";

  //     return this._http.post(apiUrl + _apimethod, null, this._options)
  //         .map((response: Response) => <string>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetTokenCasinoVIG(apiUrl: string, f: AuthenticationDto): Observable<string> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetTokenCasinoVIG";

  //     return this._http.post(apiUrl + _apimethod, null, this._options)
  //         .map((response: Response) => <string>response.json())
  //         .catch(this.handleError);

  // }

  // GetCasinoInfo(apiUrl: string, BrandId: number, f: AuthenticationDto): Observable<CasinoInfoModel> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetCasinoInfo?BrandId=" + BrandId;

  //     return this._http.post(apiUrl + _apimethod, null, this._options)
  //         .map((response: Response) => <CasinoInfoModel>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetLiveStreamUrl(apiUrl: string, f: AuthenticationDto): Observable<string> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetLiveStreamUrl";

  //     return this._http.post(apiUrl + _apimethod, null, this._options)
  //         .map((response: Response) => <string>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetSiteMessages(apiUrl: string, IdSite: string): Observable<SiteMessagesDto[]> {

  //     let _apimethod: string = "Player/GetSiteMessages?IdSite=" + IdSite;

  //     return this._http.get(apiUrl + _apimethod, this._options)
  //         .map((response: Response) => <SiteMessagesDto[]>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetAccessCasinoVivoGaming(apiUrl: string, f: AuthenticationDto): Observable<CasinoTomHornModel> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetAccessCasinoVivoGaming";

  //     return this._http.post(apiUrl + _apimethod, null, this._options)
  //         .map((response: Response) => <CasinoTomHornModel>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetAllGamesCategory(apiUrl: string, pRequest: RequestGamesCategory, f: AuthenticationDto,): Observable<CategoryDto[]> {
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetAllGamesCategory";

  //     return this._http.post(apiUrl + _apimethod, pRequest, this._options)
  //         .map((response: Response) => <CategoryDto[]>response.json())
  //         .catch(this.handleError);

  // } //end function

  // InsertUpdateFavoriteGameByPlayer(apiUrl: string, pGame: GameDto, f: AuthenticationDto): Observable<boolean> {
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/InsertUpdateFavoriteGameByPlayer";
  //     return this._http.post(apiUrl + _apimethod, pGame, this._options)
  //         .map((response: Response) => <boolean>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetBanksProvidersBySite(apiUrl: string, pRquest: RequestBanksProvidersDto, f: AuthenticationDto): Observable<ReponseBanksProvidersDto> {
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetBanksProvidersBySite";
  //     return this._http.post(apiUrl + _apimethod, pRquest, this._options)
  //         .map((response: Response) => <ReponseBanksProvidersDto>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetBonusActiveBetsoftCash(apiUrl: string, pRquest: RequestBetsoftBonusCash, f: AuthenticationDto): Observable<BonusTransactionDto[]> {
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetBonusActiveBetsoftCash";
  //     return this._http.post(apiUrl + _apimethod, pRquest, this._options)
  //         .map((response: Response) => <BonusTransactionDto[]>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetBonusActiveBetsoftRounds(apiUrl: string, pRquest: RequestBetsoftBonusRounds, f: AuthenticationDto): Observable<BonusTransactionDto[]> {
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetBonusActiveBetsoftRounds";
  //     return this._http.post(apiUrl + _apimethod, pRquest, this._options)
  //         .map((response: Response) => <BonusTransactionDto[]>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetBonusActiveNucleusCash(apiUrl: string, pRquest: RequestBetsoftBonusCash, f: AuthenticationDto): Observable<BonusTransactionDto[]> {
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetBonusActiveNucleusCash";
  //     return this._http.post(apiUrl + _apimethod, pRquest, this._options)
  //         .map((response: Response) => <BonusTransactionDto[]>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetBonusActiveNucleusRounds(apiUrl: string, pRquest: RequestBetsoftBonusRounds, f: AuthenticationDto): Observable<BonusTransactionDto[]> {
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetBonusActiveNucleusRounds";
  //     return this._http.post(apiUrl + _apimethod, pRquest, this._options)
  //         .map((response: Response) => <BonusTransactionDto[]>response.json())
  //         .catch(this.handleError);

  // } //end function

  // InitEndorphinaGame(apiUrl: string, pRquest: RequestEndorphinaDto, f: AuthenticationDto): Observable<ResponseEndorphinaDto> {
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/InitEndorphinaGame";
  //     return this._http.post(apiUrl + _apimethod, pRquest, this._options)
  //         .map((response: Response) => <ResponseEndorphinaDto>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetAccessBetConnectionsSports(apiUrl: string, pRquest: RequestSoccerUltraDto, f: AuthenticationDto): Observable<string> {

  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetAccessBetConnectionsSports";

  //     return this._http.post(apiUrl + _apimethod, pRquest, this._options)
  //         .map((response: Response) => <string>response.json())
  //         .catch(this.handleError);

  // }
  // CheckIfMinimumBalanceOfCashier(apiUrl: string): Observable<boolean> {

  //     let _apimethod: string = "Player/CheckIfMinimumBalanceOfCashier";

  //     return this._http.get(apiUrl + _apimethod, this._options)
  //         .map((response: Response) => <boolean>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetAccessSuicidePool(apiUrl: string, f: AuthenticationDto): Observable<number> {
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/GetAccessSuicidePool";

  //     return this._http.get(apiUrl + _apimethod, this._options)
  //         .map((response: Response) => <number>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetAllLinesEsports(apiUrl: string): Observable<any> {
  //     let _apimethod = "Guest/GetAllLinesEsports";
  //     return this._http.get(apiUrl + _apimethod)
  //         .map((response: Response) => <any>response.json());

  // }

  // GetGradedBets(apiUrl: string, t: RequestPlayerHistory, f: AuthenticationDto): Observable<OpenBetItem[]> {

  //     //use same  object (openBets).
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);

  //     let _apimethod = "Player/GetGradedBets";

  //     return this._http.post(apiUrl + _apimethod, t, this._options)
  //         .map((response: Response) => <OpenBetItem[]>response.json());
  // }

  // GetEncodeToken(f: AuthenticationDto) {
  //     return btoa(`${f.AccountName}:${f.Password || "-1"}${f.Token ? `:${f.Token}` : ""}`);
  // }

  // InitPlayForFree(apiUrl: string, pRquest: PlayForFreeRequest): Observable<PlayForFreeResponse> {
  //     let _apimethod = "Guest/InitPlayForFree";
  //     return this._http.post(apiUrl + _apimethod, pRquest)
  //         .map((response: Response) => <PlayForFreeResponse>response.json());
  // }

  // InitProduct(apiUrl: string, pRquest: InitProductRequestDto, f: AuthenticationDto): Observable<InitProductResponseDto> {
  //     let encode = this.GetEncodeToken(f);
  //     this._options.headers.set('Authorization', 'Token ' + encode);
  //     let _apimethod: string = "Player/InitProduct";
  //     return this._http.post(apiUrl + _apimethod, pRquest, this._options)
  //         .map((response: Response) => <InitProductResponseDto>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetVirtualSportTeams(apiUrl: string, pRequest: VirtualSportTeamRequestDto): Observable<VirtualSportTeamDto[]> {
  //     let _apimethod: string = "Guest/GetVirtualSportTeams";
  //     return this._http.post(apiUrl + _apimethod, pRequest, this._options)
  //         .map((response: Response) => <VirtualSportTeamDto[]>response.json())
  //         .catch(this.handleError);

  // } //end function

  // GetScheduleGames(apiUrl: string, pRequest: ScheduleGameRequestDto): Observable<ScheduleGameResponseDto[]> {
  //     let _apimethod: string = "Guest/GetScheduleGames";
  //     return this._http.post(apiUrl + _apimethod, pRequest, this._options)
  //         .map((response: Response) => <ScheduleGameResponseDto[]>response.json())
  //         .catch(this.handleError);

  // } //end function

  /***************************** CASHIER **********************************/
  /***************************** CASHIER **********************************/

  CashierTrans(
    t: CashierTransDTO,
    f: AuthenticationDto
  ): Observable<CashierTransRequestDTO> {
    const a: CashierTransRequestDTO = new CashierTransRequestDTO();
    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/PlayerCashier";
    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: CashierTransRequestDTO) => response),
      catchError(this.handleError<string>("Token"))
    );
  }

  CashierGetTrans(
    t: CashierGetTransDTO,
    f: AuthenticationDto
  ): Observable<CashierReportTransDTO[]> {
    const a: CashierReportTransDTO = new CashierReportTransDTO();
    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/PlayerCashierGetTrans";
    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: CashierReportTransDTO[]) => response),
      catchError(this.handleError<string>("Token"))
    );
  } // end CASHIER

  private handleError<T>(operation = "operation", result?: T) {
    return (errorResponse: any): Observable<any> => {
      // const errorObj: any;
      // errorObj.status = errorResponse.status == null ? 0 : errorResponse.status;
      // if (errorResponse.error.errors != null && errorResponse.error.errors.detail) {
      //   errorObj.errorMessage = errorResponse.error.errors.detail;
      // }
      return of(errorResponse as any);
    };
  }
}
