export class GameSelectedList {
    GameList: LeagueSelected[];
    HideLines: boolean = false;
}

export class LeagueSelected {

    NHLLine: string;
    WagerType: number;
    IdLeague: number;
    IdSport: string;
    Description: String;
    Hide: boolean = false;
    Games: Games[];
}

export class Games {

    IdGame: number;
    Idgmtyp: number;
    Gmdt: string;
    Gmtm: string;
    Idspt: string;
    Vnum: number;
    Hnum: number;
    Idgp: number;
    Vtm: string;
    Htm: string;
    Banner: string[];
    // Banner2: string;
    // Banner3: string;
    Hpt: string; //Home pitcher
    Vpt: string; //Visitor pitcher
    Gdesc: string;
    Lines: Line[];
    Childs: Games[];
    ShowChilds:boolean = false;
    ShowThisGame:boolean = true;
    For1X2:boolean = false;
    VisitorLogoPath:string;
    HomeLogoPath: string;

    DigitalSportsUrl: any;
    ViewDigitalSports: boolean;
}

export class Line {
    Bml: string;
    Bsprd: string;
    Btot: string;
    DrawSelected: boolean;
    EmptyGame: boolean;
    Haschild: boolean;
    Hoddsh: string;
    Hoddst: string;
    HomeMlSelected: boolean;
    HomeSpreadSelected: boolean;
    HomeTotalSelected: boolean;
    Hsph: string;
    Hspoddst: string;
    Hsprdh: string;
    Hsprdoddst: string;
    Hsprdt: string;
    Hspt: string;
    IsTnt: boolean;
    Odds: string;
    Oddsh: string;
    Ovh: string;
    Ovhr: number;
    Ovoddst: string;
    Ovt: string;
    Related: boolean;
    Tmname: string;
    Tmnum: number;
    TntSelected: boolean;
    Unh: string;
    Unoddst: string;
    Unt: string;
    VisitorMlSelected: boolean;
    VisitorSpreadSelected: boolean;
    VisitorTotalSelected: boolean;
    Voddsh: string;
    Voddshr: number;
    Voddst: string;
    Vsph: string;
    Vsphr: number;
    Vspoddst: string;
    Vsprdh: string;
    Vsprdhr: number;
    Vsprdoddst: string;
    Vsprdt: string;
    Vspt: string;
    ShowThisLine:boolean = true;
}