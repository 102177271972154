import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";
import { LoginUserDto, PlayerDto } from "../models/user";
import { AuthenticationDto } from "../models/wagerModels";
import { TokenRequestDTO } from "../models/genericModels";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class AuthServices {
  constructor(private httpClient: HttpClient, private router: Router) {}

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("currentUser");
  }

  Login(t: LoginUserDto, f: AuthenticationDto): Observable<PlayerDto> {
    const a: PlayerDto = new PlayerDto();

    t.IdSite = environment.idSite;

    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    t.UserName = f.AccountName;
    t.Password = f.Password;
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/PlayerLogin";

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: PlayerDto) => response),
      catchError(this.handleError<PlayerDto>("Login", a))
    );
  } // end Login

  GetIP(): Observable<any> {
    return this.httpClient
      .get("http://api.ipify.org/?format=jsonp&callback=JSONP_CALLBACK")
      .pipe(
        map((res: Response) => {
          return res;
        })
      );
  }

  HorsesToken(t: TokenRequestDTO, f: AuthenticationDto): Observable<any> {
    const a: TokenRequestDTO = new TokenRequestDTO();

    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl = environment.apiUrl + "Player/PlayerHorsesToken";
    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: string) => response),
      catchError(this.handleError<string>("Token"))
    );
  } // end Login

  private handleError<T>(operation = "operation", result?: T) {
    return (errorResponse: any): Observable<any> => {
      // const errorObj: any;
      // errorObj.status = errorResponse.status == null ? 0 : errorResponse.status;
      // if (errorResponse.error.errors != null && errorResponse.error.errors.detail) {
      //   errorObj.errorMessage = errorResponse.error.errors.detail;
      // }
      return of(errorResponse as any);
    };
  }
}
