import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import {
  CompiledObject,
  PlayerParleyLimitsDetailsDto,
} from "../models/wagerModels";
import { LineCompile } from "../models/genericModels";
import { AuthenticationDto } from "../models/wagerModels";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class MiscService {
  constructor(private httpClient: HttpClient) {}

  k_combinations(set, k) {
    var i, j, combs, head, tailcombs;

    if (k > set.length || k <= 0) {
      return [];
    }

    // K-sized set has only one K-sized subset.
    if (k == set.length) {
      return [set];
    }

    // There is N 1-sized subsets in a N-sized set.
    if (k == 1) {
      combs = [];
      for (i = 0; i < set.length; i++) {
        combs.push([set[i]]);
      }
      return combs;
    }
    combs = [];

    for (i = 0; i < set.length - k + 1; i++) {
      // head is a list that includes only our current element.
      head = set.slice(i, i + 1);
      // We take smaller combinations from the subsequent elements
      tailcombs = this.k_combinations(set.slice(i + 1), k - 1);
      // For each (k-1)-combination we join it with the current
      // and store it to the set of k-combinations.
      for (j = 0; j < tailcombs.length; j++) {
        combs.push(head.concat(tailcombs[j]));
      }
    }
    return combs;
  }

  /**
   * Combinations
   * Get all possible combinations of elements in a set.
   */
  combinations(set) {
    var k, i, combs, k_combs;
    combs = [];

    // Calculate all non-empty k-combinations
    for (k = 1; k <= set.length; k++) {
      k_combs = this.k_combinations(set, k);
      for (i = 0; i < k_combs.length; i++) {
        combs.push(k_combs[i]);
      }
    }
    return combs;
  }

  CalculateWin(odds: number, betAmount: number): number {
    let amountCalculate: number = 0;

    if (odds < 0) {
      //negativo
      amountCalculate = -1 * ((100 * betAmount) / odds);
    } else {
      amountCalculate = (odds * betAmount) / 100;
    }

    return amountCalculate;
  }

  // GetLittleParlayFactor(CompiledObjectResult: CompiledObject): number {

  //     let factor: number = 1;

  //     for (let littleItem of CompiledObjectResult.CompiledDetailsList[0].CompiledLineDetailsList) {

  //         let originalOdd = littleItem.OriginalOdds;
  //         //console.log(originalOdd);

  //         if (originalOdd > 100)//positive
  //         {
  //             let x = (originalOdd / 100) + 1;
  //             factor = factor * x;
  //         }
  //         else {
  //             let y = (100 / (-1 * originalOdd)) + 1;
  //             //console.log('y: ' + y);
  //             factor = factor * y;
  //         }
  //     }
  //     return -1 + factor;
  // }

  // OLD FACTOR
  // GetParlayFactor(CompiledObjectResult: CompiledObject): number {

  //     let factor: number = 1;

  //     for (let littleItem of CompiledObjectResult.CompiledDetailsList[0].CompiledLineDetailsList) {

  //         let originalOdd: number = 0;
  //         if (littleItem.NewLineOdds != null && littleItem.NewLineOdds != 0)
  //             originalOdd = littleItem.NewLineOdds;
  //         else
  //             originalOdd = littleItem.OriginalOdds;

  //         //console.log(originalOdd);

  //         if (originalOdd >= 100)//positive
  //         {
  //             let x = (originalOdd / 100) + 1;
  //             factor = factor * x;
  //         }
  //         else {
  //             let y = (100 / (-1 * originalOdd)) + 1;
  //             //console.log('y: ' + y);
  //             factor = factor * y;
  //         }
  //     }
  //     return -1 + factor;
  // }

  // GetParlayFactor(CompiledObjectResult: CompiledObject, _limits: PlayerParleyLimitsDetailsDto[]): number {

  //     let _tmpFactor: number = 1;

  //     if (CompiledObjectResult) {
  //         if (CompiledObjectResult.CompiledDetailsList) {

  //             if (_limits) {

  //                 let _tmpTeams: number = CompiledObjectResult.CompiledDetailsList[0].CompiledLineDetailsList.length;
  //                 let _tmpLimits = _limits.find(c => c.NumTeams === _tmpTeams);

  //                 _tmpFactor = (_tmpLimits) ? _tmpLimits.Odds : 1;

  //             } else
  //                 _tmpFactor = 1;
  //         }
  //     }

  //     return _tmpFactor;
  // }

  /**
   * Update
   * Use parley limits
   * Rsc - Bryan
   * 02-02-2020
   * @param CompiledObjectResult
   */
  GetParlayFactor(CompiledObjectResult: CompiledObject): number {
    let factor: number = 1;

    if (!CompiledObjectResult || !CompiledObjectResult.CompiledDetailsList)
      return 0;

    for (let item of CompiledObjectResult.CompiledDetailsList[0]
      .CompiledLineDetailsList) {
      let originalOdd: number =
        item.NewLineOdds != null && item.NewLineOdds != 0
          ? item.NewLineOdds
          : item.OriginalOdds;

      if (originalOdd > 0) factor *= originalOdd / 100 + 1;
      else factor *= 100 / (-1 * originalOdd) + 1;
    }

    return factor;
  }

  GetWinAmount(ArrayOfOdds: any, Amount: number): number {
    let win: number = 0;

    for (let row of ArrayOfOdds) {
      let factor: number = 1;

      for (let originalOdd of row) {
        if (originalOdd > 100) {
          //positive
          let x = originalOdd / 100 + 1;
          factor = factor * x;
        } else {
          let y = 100 / (-1 * originalOdd) + 1;
          //console.log('y: ' + y);
          factor = factor * y;
        }
      }
      win = win + (-1 + factor) * Amount;
      // console.log('Amount: ' + Amount);
      // console.log('Factor: ' + factor);
      // console.log('Win: ' + win);
    }

    return Math.round(win);
  }

  SelectionHasAsianHandiCap(arrayOfLines: LineCompile[]): boolean {
    let isAsian: boolean = false;

    for (let sel of arrayOfLines) {
      if (
        sel.Play != 4 &&
        sel.Play != 5 &&
        sel.Play != 6 &&
        sel.IdSport == "SOC"
      ) {
        //this is only for Total
        let decimalPart: number = sel.Points % 1;

        if (
          decimalPart == 0.25 ||
          decimalPart == 0.75 ||
          decimalPart == -0.25 ||
          decimalPart == -0.75
        ) {
          isAsian = true;
          break;
        }
      }
    }

    return isAsian;
  }

  // GetWinReverseMultiplyFactor(totalSelections: number): number {
  //     if (totalSelections == 2)
  //         return 2;
  //     else if (totalSelections == 3) {
  //         return 4
  //     }
  //     else {
  //         return 6;
  //     }
  // }

  CalcReverseRiskWin(
    firstOdd: number,
    secondOdd: number,
    amount: number
  ): number[] {
    let result: number[] = [];

    let firstRiskWin = this.CalcRiskAndWinFromAmount(firstOdd, amount);
    let secondRiskWin = this.CalcRiskAndWinFromAmount(secondOdd, amount);

    let excendente: number = 0;

    if (firstRiskWin[0] + firstRiskWin[1] < secondRiskWin[0]) {
      excendente =
        excendente + (secondRiskWin[0] - (firstRiskWin[0] + firstRiskWin[1]));
    }
    if (secondRiskWin[0] + secondRiskWin[1] < firstRiskWin[0]) {
      excendente =
        excendente + (firstRiskWin[0] - (secondRiskWin[0] + secondRiskWin[1]));
    }

    let finalRisk: number = firstRiskWin[0] + secondRiskWin[0] + excendente;
    let finalWin: number = firstRiskWin[1] * 2 + secondRiskWin[1] * 2;

    result.push(finalRisk);
    result.push(finalWin);

    return result;
  }

  CalcActionReverse(
    firstOdd: number,
    secondOdd: number,
    amount: number
  ): number[] {
    let result: number[] = [];
    let finalRisk: number = 0;
    let finalWin: number = 0;

    let firstRiskWin = this.CalcRiskAndWinFromAmount(firstOdd, amount);
    let secondRiskWin = this.CalcRiskAndWinFromAmount(secondOdd, amount);

    if (firstRiskWin[0] < secondRiskWin[0]) {
      finalRisk = secondRiskWin[0] * 2;
    } else {
      finalRisk = firstRiskWin[0] * 2;
    }

    finalWin = firstRiskWin[1] * 2 + secondRiskWin[1] * 2;

    result.push(finalRisk);
    result.push(finalWin);

    return result;
  }

  /**
   *
   * RiskWin: 1 => Win
   * RiskWin 2 => Risk
   */

  CalcRiskWin(odd: number, WagerAmount: number, RiskWin: number): number {
    let resultValue: number;

    if (RiskWin == 1) {
      // win  => then return risk
      // let resultWin: number = WagerAmount;
      if (odd < 0) {
        resultValue = WagerAmount * ((-1 * odd) / 100);
      } else {
        resultValue = (WagerAmount * 100) / odd;
      }
    } //risk => then return win
    else {
      // let resultWin: number = WagerAmount;
      if (odd < 0) {
        resultValue = WagerAmount * (100 / (-1 * odd));
      } else {
        resultValue = (WagerAmount * odd) / 100;
      }
    }
    return resultValue;
  }

  CalcRiskAndWinFromAmount(odd: number, WagerAmount: number): number[] {
    let resultvalue: number[] = [];

    let risk: number = 0;
    let win: number = 0;

    if (odd < 0) {
      risk = (WagerAmount * (-1 * odd)) / 100;
      win = WagerAmount;
    } else {
      risk = WagerAmount;
      win = (WagerAmount * odd) / 100;
    }

    resultvalue.push(risk);
    resultvalue.push(win);

    return resultvalue;
  }

  GetPlayerProfileParlayLimits(
    t: number,
    f: AuthenticationDto
  ): Observable<any> {
    const encode = btoa(f.AccountName + ":" + f.Password);
    const headers = new HttpHeaders({
      Authorization: "Token " + encode,
      "Content-Type": "application/json",
    });
    const options = { headers };
    const apiUrl =
      environment.apiUrl + "Player/GetPlayerProfileParlayLimits?numTeams=" + t;
    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: number) => response),
      catchError(this.handleError<string>("parlay calculate"))
    );
  }
  private handleError<T>(operation = "operation", result?: T) {
    return (errorResponse: any): Observable<any> => {
      // const errorObj: any;
      // errorObj.status = errorResponse.status == null ? 0 : errorResponse.status;
      // if (errorResponse.error.errors != null && errorResponse.error.errors.detail) {
      //   errorObj.errorMessage = errorResponse.error.errors.detail;
      // }
      return of(errorResponse as any);
    };
  }
} //end service
