import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';

import { CompileWagerDto } from '../models/genericModels'

import { LeagueSelected } from '../models/GetScheduleModels';
import {
    CompiledObject,
    TeaserWagerTypesModel, TeaserWagerTypeDto, RequestGetLinesDto, SportLeaguesInfoModel, AuthenticationDto, ListCompiledObjectList, PlayerParleyLimitsDetailsDto
} from '../models/wagerModels';
import { RequestGetLeaguesDto } from '../models/user';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class LinesService {

    constructor(private httpClient: HttpClient, private router: Router) { }

    GetActiveLeagues(t: RequestGetLeaguesDto, f: AuthenticationDto): Observable<SportLeaguesInfoModel[]> {

        let a: SportLeaguesInfoModel[];
    
        const encode = btoa(f.AccountName + ':' + f.Password);
        const headers = new HttpHeaders({
          Authorization: 'Token ' + encode,
          'Content-Type': 'application/json'
        });
        const options = { headers };
    
        const apiUrl = environment.apiUrl + 'Lines/GetActiveLeagues';
    
        return this.httpClient.post(apiUrl, t, options).pipe(
          map((response: SportLeaguesInfoModel[]) => response),
          catchError(this.handleError<SportLeaguesInfoModel[]>('GetActiveLeagues', a))
        );
    
      } // end Method

    // GetActiveLeagues2(apiUrl: string, t: RequestGetLeaguesDto, f: AuthenticationDto, IsGuest: boolean): Observable<SiteSportsDto[]> {

    //     let _apimethod: string = "";

    //     if (IsGuest) {
    //         _apimethod = "Guest/GetActiveLeagues2";
    //     }
    //     else {
    //         let encode = this.GetEncodeToken(f);
    //         this._options.headers.set('Authorization', 'Token ' + encode);
    //         _apimethod = "Lines/GetActiveLeagues2";
    //     }


    //     return this._http.post(apiUrl + _apimethod, t, this._options)
    //         .map((response: Response) => <SiteSportsDto[]>response.json())
    //         .catch(this.handleError);
    // }


    GetLines(t: RequestGetLinesDto, f: AuthenticationDto): Observable<LeagueSelected> {

        const a: LeagueSelected = new LeagueSelected();
    
        const encode = btoa(f.AccountName + ':' + f.Password);
        const headers = new HttpHeaders({
          Authorization: 'Token ' + encode,
          'Content-Type': 'application/json'
        });
        const options = { headers };
    
        const apiUrl = environment.apiUrl + 'Lines/GetNewLines';
    
        //console.log(apiUrl);
    
        return this.httpClient.post(apiUrl, t, options).pipe(
          map((response: LeagueSelected) => response),
          catchError(this.handleError<LeagueSelected>('GetLines', a))
        );
    
      }// end Method


      Compile(t: CompileWagerDto, f: AuthenticationDto): Observable<CompiledObject> {

        const a: CompiledObject = new CompiledObject();
        const encode = btoa(f.AccountName + ':' + f.Password);
        const headers = new HttpHeaders({
          Authorization: 'Token ' + encode,
          'Content-Type': 'application/json'
        });
        const options = { headers };
        const apiUrl = environment.apiUrl +   'Wager/WagerCompile';
    
        return this.httpClient.post(apiUrl, t, options).pipe(
          map((response: CompiledObject) => response),
          catchError(this.handleError<CompiledObject>('Confirm', a))
        );
    
      } // end function



  CompileList(t: CompileWagerDto, f: AuthenticationDto): Observable<ListCompiledObjectList> {

    const a: ListCompiledObjectList = new ListCompiledObjectList();
    const encode = btoa(f.AccountName + ':' + f.Password);
    const headers = new HttpHeaders({
      Authorization: 'Token ' + encode,
      'Content-Type': 'application/json'
    });
    const options = { headers };
    const apiUrl = environment.apiUrl +  'Wager/WagerListCompile';

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: ListCompiledObjectList) => response),
      catchError(this.handleError<ListCompiledObjectList>('CompileList', a))
    );
  } // end function

  ConfirmAndPostList(t: ListCompiledObjectList, f: AuthenticationDto): Observable<ListCompiledObjectList> {

    const a: ListCompiledObjectList = new ListCompiledObjectList();
    const encode = btoa(f.AccountName + ':' + f.Password);
    const headers = new HttpHeaders({
      Authorization: 'Token ' + encode,
      'Content-Type': 'application/json'
    });
    const options = { headers };
    const apiUrl = environment.apiUrl +  'Wager/WagerListConfirmAndPost';

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: ListCompiledObjectList) => response),
      catchError(this.handleError<ListCompiledObjectList>('ConfirmAndPostList', a))
    );

  } // end functionf

  WagerListPost(t: ListCompiledObjectList, f: AuthenticationDto): Observable<ListCompiledObjectList> {

    const a: ListCompiledObjectList = new ListCompiledObjectList();
    const encode = btoa(f.AccountName + ':' + f.Password);
    const headers = new HttpHeaders({
      Authorization: 'Token ' + encode,
      'Content-Type': 'application/json'
    });
    const options = { headers };
    const apiUrl = environment.apiUrl +  'Wager/WagerListPost';

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: ListCompiledObjectList) => response),
      catchError(this.handleError<ListCompiledObjectList>('WagerListPost', a))
    );

  } // end functionf

  Confirm(t: CompiledObject, f: AuthenticationDto): Observable<CompiledObject> {

    const a: CompiledObject = new CompiledObject();
    const encode = btoa(f.AccountName + ':' + f.Password);
    const headers = new HttpHeaders({
      Authorization: 'Token ' + encode,
      'Content-Type': 'application/json'
    });
    const options = { headers };
    const apiUrl = environment.apiUrl +  'Wager/WagerConfirm';

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: CompiledObject) => response),
      catchError(this.handleError<CompiledObject>('Confirm', a))
    );
  } // end function


  Post(t: CompiledObject, f: AuthenticationDto): Observable<CompiledObject> {

    const a: CompiledObject = new CompiledObject();
    const encode = btoa(f.AccountName + ':' + f.Password);
    const headers = new HttpHeaders({
      Authorization: 'Token ' + encode,
      'Content-Type': 'application/json'
    });
    const options = { headers };
    const apiUrl = environment.apiUrl +  'Wager/WagerPost';

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: CompiledObject) => response),
      catchError(this.handleError<CompiledObject>('Confirm', a))
    );

  } // end function

  ComfirmPost(t: CompiledObject, f: AuthenticationDto): Observable<CompiledObject> {

    const a: CompiledObject = new CompiledObject();
    const encode = btoa(f.AccountName + ':' + f.Password);
    const headers = new HttpHeaders({
      Authorization: 'Token ' + encode,
      'Content-Type': 'application/json'
    });
    const options = { headers };
    const apiUrl = environment.apiUrl +  'Wager/WagerConfirmPost';

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: CompiledObject) => response),
      catchError(this.handleError<CompiledObject>('Confirm', a))
    );

  } // end function



  GetWagerTypes(t: TeaserWagerTypesModel, f: AuthenticationDto): Observable<TeaserWagerTypeDto[]> {
    let a: TeaserWagerTypeDto[];
    const encode = btoa(f.AccountName + ':' + f.Password);
    const headers = new HttpHeaders({
      Authorization: 'Token ' + encode,
      'Content-Type': 'application/json'
    });
    const options = { headers };
    const apiUrl = environment.apiUrl +   'Wager/GetWagerType';

    return this.httpClient.post(apiUrl, t, options).pipe(
      map((response: TeaserWagerTypeDto[]) => response),
      catchError(this.handleError<TeaserWagerTypeDto[]>('Confirm', a))
    );

  } // end function



    FillCompile(t: CompileWagerDto, f: AuthenticationDto, IsGuest: boolean): Observable<CompiledObject> {


        let a: CompiledObject = new CompiledObject;
        const encode = btoa(f.AccountName + ':' + f.Password);
        const headers = new HttpHeaders({
          Authorization: 'Token ' + encode,
          'Content-Type': 'application/json'
        });
        const options = { headers };
        const apiUrl = environment.apiUrl +   'Wager/WagerFillCompile';


        return this.httpClient.post(apiUrl, t, options).pipe(
            map((response: CompiledObject) => response),
            catchError(this.handleError<CompiledObject>('Confirm', a))
          );

    } //end function

    // InserteSportBet(t: RequestESportsBetDto[], f: AuthenticationDto): Observable<ResponseESportsBetDto> {

    //     let a: CompiledObject = new CompiledObject;
    //     const encode = btoa(f.AccountName + ':' + f.Password);
    //     const headers = new HttpHeaders({
    //       Authorization: 'Token ' + encode,
    //       'Content-Type': 'application/json'
    //     });
    //     const options = { headers };
    //     const apiUrl = environment.apiUrl +   'Wager/InserteSportBet';


    //     return this._http.post(apiUrl + _apimethod, t, this._options)
    //         .map((response: Response) => <ResponseESportsBetDto>response.json())
    //         .catch(this.handleError);

    // } //end function



    GetPlayerParleyLimits(Player: string, f: AuthenticationDto, IsGuest: boolean): Observable<PlayerParleyLimitsDetailsDto[]> {


        let a: PlayerParleyLimitsDetailsDto[];
        const encode = btoa(f.AccountName + ':' + f.Password);
        const headers = new HttpHeaders({
          Authorization: 'Token ' + encode,
          'Content-Type': 'application/json'
        });
        const options = { headers };
        const apiUrl = environment.apiUrl +   'Lines/GetPlayerParleyLimits';


        return this.httpClient.get(apiUrl, options).pipe(
            map((response: PlayerParleyLimitsDetailsDto[]) => response),
            catchError(this.handleError<PlayerParleyLimitsDetailsDto[]>('Confirm', a))
          );
    }



    GetEncodeToken(f: AuthenticationDto) {
        return btoa(`${f.AccountName}:${f.Password || "-1"}${f.Token ? `:${f.Token}` : ""}`);
    }


    private handleError<T>(operation = 'operation', result?: T) {
        return (errorResponse: any): Observable<any> => {
            // const errorObj: any;
            // errorObj.status = errorResponse.status == null ? 0 : errorResponse.status;
            // if (errorResponse.error.errors != null && errorResponse.error.errors.detail) {
            //   errorObj.errorMessage = errorResponse.error.errors.detail;
            // }
            return of(errorResponse as any);
        };
    }

}