import { Component, Inject, OnInit } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { CookieService } from "ngx-cookie-service";
import { TranslateService } from "@ngx-translate/core";
import { Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";
import { DataService } from "./CoreWager/services/DataService";
import { AuthServices } from "./CoreWager/services/auth.service";
import { LocationService } from "./CoreWager/models/signupModels";
import { ConfigService } from "./CoreWager/services/configService";
import { PlayerService } from "./CoreWager/services/player.service";
import { ConfigSite, WebConfigDto } from "./CoreWager/models/genericModels";
import { ConfigurationSiteClass } from "./CoreWager/shared-modules/Class/ConfigurationSiteClass";
import {
  siteCountryLanguage,
  metaTagsBySiteLanguage,
  unbounceTokenSite,
  HomePageMessageBySiteLanguageDto,
} from "./CoreWager/models/cms";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  // Class
  _configSites: ConfigurationSiteClass;

  _goQuickAccess: boolean;

  _model: any = {};
  _language: string = "en";
  _configData: ConfigSite;
  _hostname: string;
  _SiteConfiguration: WebConfigDto;
  _apiLocationIntents: number;
  _location: LocationService;
  _siteLanguages: siteCountryLanguage[];
  _metaTags: metaTagsBySiteLanguage[];

  _unbounce: unbounceTokenSite;
  _removedChat: boolean = false;
  _homePageMessage: HomePageMessageBySiteLanguageDto;
  _showHomePageMessage = true;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private router: Router,
    private _Config: ConfigService,
    private _DataService: DataService,
    private _autService: AuthServices,
    private _translate: TranslateService
  ) {
    sessionStorage.setItem("sessionActualPath", window.location.pathname); // Set Actual Path

    _translate.addLangs(["en", "es", "pes"]);
    _translate.setDefaultLang("en");
    //this.affiliateCookies();
  }

  ngOnInit() {
    const domainHost = environment.host;
    this.document
      .getElementById("theme")
      .setAttribute("href", "./assets/sitename/" + domainHost + "/default.css");
    this._DataService.changeLanguage(environment.defaultLang); // Get language of Site
    // this._Config.GetDomainsConfig().subscribe(domains => {

    //   if (domains && domains.Domains) {

    //     let _tmpSite = ((this._configData.DefaultHostName != "") ? this._configData.DefaultHostName : window.location.hostname).replace("www.", "");
    //     let _tmpDomain = domains.Domains.find(c => (c.Domain == _tmpSite) || (c.Name === _tmpSite));

    //     if (_tmpDomain != null) {

    //       this._hostname = _tmpDomain.Name;
    //       this._SiteConfiguration = this._configData.WebConfig.find(c => c.Name == this._hostname);
    //       localStorage.setItem('SiteHost', _tmpDomain.Name);
    //       localStorage.setItem('domainSite', _tmpDomain.Domain);
    //       localStorage.setItem('ConfigSiteInfo', JSON.stringify(this._configData));

    //       this.document.getElementById('theme').setAttribute('href', './assets/sitename/' + this._hostname + '/default.css');

    //       if (this._SiteConfiguration) {
    //         this._DataService.changeLanguage(this._SiteConfiguration.Language); // Get language of Site

    //       }
    //     }
    //   }

    // }, error => {
    //   console.log('error get domains :( ');
    // });
  }

  UseLanguage(value: string) {
    this._DataService.changeLanguage(value);
  }

  //---END
}
