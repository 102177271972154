import { CountryDto, StateDto } from "./genericModels";
import { GenericDataDto } from "./signupModels";

export class PlayerDto {
  BonusPointsStatus: string;
  CultureInfo: string;
  Currency: string;
  CurrencySymbol: string;
  EnableCasino: string;
  EnableHorses: string;
  EnableSports: string;
  EnableParlayCards: string;
  EnforcesPassRules: string;

  IdAgent: number;
  IdBook: number;
  IdCall: number;

  IdLanguage: number;
  IdCurrency: number;
  IdGrouping: number;
  IdProfile: number;
  IdLineType: number;
  IdPlayer: number;

  IdProfileLimits: number;
  LineStyle: number;
  MLBLine: string;
  NHLLine: string;
  OnlineMessage: string[] = new Array();
  Password: string;
  PitcherDefault: number;
  UTC: number;

  OnlineWinWager: number;
  OnlinePassword: string;
  DuplicatedBetsOnline: boolean;
  FreePlayAmount: number;
  Status: string;
  OnlineAccess: boolean;

  IdOffice: number;
  HoldBetsL: boolean;
  Gmt: number;
  ClMaxWager: number;
  ResetPassword: boolean;

  Player: string;
  Agent: string;
  Name: string;
  PlayerAvatar: string;
  OptionInitDefault: string;
  Products: ProductDto[];
  BLRTheme: string;

  Hold: boolean = false;
  HoldDelay: number = 0;
  Token: string;
}

export class AccessSite {
  Title: string;
  HelpPage: string;
  CustNumber: string;
  WagerNumber: string;
  FaxNumber: string;
  WhatsAppNumber: string;

  CustEmail: string;
  WagerEmail: string;

  AccessWhatsAppNumber: boolean;

  AccessCasino: boolean;
  AccessLiveWager: boolean;
  AccessLiveDealer: boolean;
  AccessRacebook: boolean;
  AccessRacebookPlatinum: boolean;
  AccessCashier: boolean;
  AccessAccountSetting: boolean;
  AccessBonusPoints: boolean;
  AccessMessage: boolean;
  AccessFillOpen: boolean;
  AccessHistory: boolean;
  AccessPool: boolean;
  AccessSquares: boolean;
  AccessParlayCards: boolean;
  // AccessSuicidePool : boolean;
  AccessChat: boolean;
}

export class ProductDto {
  public Id: number;
  public Name: string;
  public ImageUrl: string;
  public CategoryId: number;
  public Active: boolean;
  public Hidden: boolean;
}
export class PlayerProducts {
  BetConnectionSports: boolean;
  // Casino
  Platinum: boolean;
  Concept: boolean;
  BetSoft: boolean;
  Nucleus: boolean;

  // Racebook
  RacebookClassic: boolean;
  RacebookPlatinum: boolean;

  // Lives
  LiveWagerGold: boolean;
  LiveDealerEz: boolean;

  // Tom Horn
  TomHorn: boolean;
  VivoGaming: boolean;

  // Endorphina
  Endorphina: boolean;

  MiniGames: boolean;
  LiveStreaming: boolean;

  //Contest
  Pool: boolean;
  Squares: boolean;
  ParlayCards: boolean;
  SuicidePool: boolean;

  // Digital Sports
  DigitalSports: boolean;
  DigitalSportsWidget: boolean;

  // e-sports
  Esports: boolean;

  // Betconnection Horses
  BetconnectionHorses: boolean;

  //Evolution Gaming (Ezugi)
  EvolutionGaming: boolean;

  //Patagonia bingo
  Patagonia: boolean;

  // VIG
  VIG: boolean;

  // VenHorses
  VenHorses: boolean;
  //Betgames TV
  BetGamesTV: boolean;

  //GrooveGaming
  GrooveGaming: boolean;

  //XpressGaming
  XpressGaming: boolean;

  Products: ProductDto[];
}

export class PlayerTransferDto {
  IdPlayer: number;
  IdWagerType: number;
  Leagues: string[];
  IdBook: number;
  IdProfile: number;
  IdProfileLimits: number;
  IdLanguage: number;
  NhlLine: string;
  MblLine: string;
  IdLineType: number;
  LineStyle: string;
  UTC: number;
  IdTimeZone: number;
  TimeZoneDesc: string;
  IdAgent: number;
  CurrentBalance: number;
  AmountAtRisk: number;
  Available: number;
  IdCurrency: number;
  Currency: string;
  CurrencyDesc: string;
  PitcherDefault: number;
  public FreePlay: number;
  public FreePlayOddsLimit: number;
  public FreePlayMaxPayout: number;
  public FreePlayMaxFavorite: number;
  public FreePlayMaxTeams: number;
  public FreePlayAllowDuplicateBets: boolean;
  public FreePlayCheckIfficeFilters: boolean;
  public FreePlayAllowBothSides: boolean;
}

export class LoginUserDto {
  //
  UserName: string;
  Password: string;
  // IdBook: string;
  IdSite: string;
  IpAddress: string;
}

export class RequestPlayerInfo {
  IdSport: string;
  IdPlayer: number;
  IdCall: number;
}

export class RequestPlayerInfoDto {
  PlayerInfo: PlayerTransferDto;
}

export class RequestGetLeaguesDto {
  WagerType: number;
  IdPlayer: number;
  IdLanguage: number;
  Player: string;
}

export class RequestSha256 {
  Word: string;
}

export class RequestState {
  countryCode: string;
}

export class CashierTransRequestDTO {
  Response: string;
  Qr: string;
  Wallet: string;
  CryptoAmount: string;
}

export class CashierReportTransDTO {
  QrURL: string;
  TransactionID: string;
  GeneratedWallet: string;
  AmountUSD: string;
  AmountCrypto: string;
  coin: string;
  Status: string;
}

export class PlayerInfoDetailsDto {
  IdPlayer: number;

  Name: string;
  LastName: string;
  LastName2: string;

  Address1: string;
  City: string;
  State: StateDto;
  Country: CountryDto;

  Zip: string;
  Phone: string;
  //Fax: string;
  Email: string;
  DateOfBirth: Date;

  Password: string;

  DayBirth: GenericDataDto;
  MonthBirth: GenericDataDto;
  YearBirth: GenericDataDto;
}

export class PlayerForgotPassDto {
  IdSite: number;
  Player: string;
  Email: string;
}

export class PlayerRedeemPointsDto {
  IdSite: number;
  Amount: number;
}

export class PlayerResetPassDto {
  Hash: string;
  Password: string;
}

export class RequestCodePasswordDto {
  IdPlayer: number;
  Hash: string;
}

export class RequestPasswordDto {
  IdPlayer: number;
  CodeVerified: string;
  password: string;
}

export class LeagueModel {
  leagueActive: LeagueOrderModel[];
  leagueInactive: LeagueOrderModel[];
}

export class LeagueOrderModel {
  IdWebRow: number;
  Order: number;
  IsActive: boolean;
  Description: string;
}
