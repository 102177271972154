import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';


import { LinesService } from '../CoreWager/services/getLeagues.service';


import { CompileWagerDto, LineCompile, Ticket } from '../CoreWager/models/genericModels'
import { CompiledObject} from '../CoreWager/models/wagerModels';

@Injectable()

export class SharedWagerClass {


   constructor(private _linesService: LinesService) { }

    Sum(value: number): number {

        return value + 10;

    }

    // ParlayCompileAndConfirm(apiUrl:string, idSite:number, domainName: string, LinesForCompileList: LineCompile[], IdCall: string, WagerTypeSelectedId: number, 
    //     IdPlayer: number, OpenSpots: number, IdWagerType: number) {


    //   let CompiledObjectResult: CompiledObject = new CompiledObject;


    //   //compile data
    //   let compileWagerModel: CompileWagerDto = new CompileWagerDto();
    //   compileWagerModel.Details = LinesForCompileList;
    //   compileWagerModel.IdCall = IdCall;
    //   compileWagerModel.WagerType = WagerTypeSelectedId;
    //   compileWagerModel.IdWagerType = IdWagerType;
    //   compileWagerModel.IdPlayer = IdPlayer;
    //   compileWagerModel.OpenSpots = OpenSpots;


    //   //console.log(compileWagerModel);

    //   this._linesService.Compile(apiUrl, compileWagerModel)
    //     .subscribe(CompileResultModel => {
    //       return CompileResultModel;
    //     });
    // }

}