import { HttpClient } from "@angular/common/http";
import { MultiTranslateHttpLoader } from "ngx-translate-multi-http-loader";

export function HttpLoaderFactory(http: HttpClient) {

    let _version: number = Math.floor(Math.random() * 500);
    let _site: string = localStorage.getItem('domainSite');
    _site = (_site && _site != "") ? _site : window.location.hostname.replace("www.", ""); // For use translation of specific Site

    return new MultiTranslateHttpLoader(http,
        [
             { prefix: "./../assets/i18n/generic_", suffix: ".json?v=2." + _version },
            // { prefix: "./../assets/i18n/cashier_", suffix: ".json?v=2." + _version },
            // { prefix: "./../assets/i18n/" + _site + "/", suffix: '.json?v=3.' + _version },
             { prefix: "./../assets/i18n/route_", suffix: ".json?v=1." + _version }
        ]
    );
}