export const environment = {
  production: true,
  apiUrl: "https://apibs.cryptogaming.bet/api/",
  idSite: "10",
  host: "wagerdm",
  defaultLang: "en",
  LineUpdateTime: 250,
  AllowAsianHandiCapOnParlays: false,
};

export const socket = {
  socketUrl: "https://192.168.3.23:3003",
};
