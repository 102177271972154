import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, of } from "rxjs";
import { Router } from "@angular/router";
import { catchError, map } from "rxjs/operators";

import { DataService } from "./DataService";
import {
  IsoCodeCountryModel,
  DomainConfigDto,
  QuickAccessDto,
} from "../models/genericModels";

@Injectable()
export class ConfigService {
  constructor(
    private httpClient: HttpClient,
    private router: Router,
    private dataServide: DataService
  ) {}

  GetDomainsConfig(): Observable<DomainConfigDto> {
    let random = Math.floor(Math.random() * 100);

    let a: DomainConfigDto;

    return this.httpClient
      .get("../../assets/domains.json?v=2." + random.toString())
      .pipe(
        map((response: DomainConfigDto) => response),
        catchError(this.handleError<DomainConfigDto>("GetPlayerInfoDetails", a))
      );
  } //end function

  GetCountryCode(): Observable<IsoCodeCountryModel> {
    let a: IsoCodeCountryModel;

    return this.httpClient.get("../../assets/Json/CodeCountry.json").pipe(
      map((response: IsoCodeCountryModel) => response),
      catchError(
        this.handleError<IsoCodeCountryModel>("GetPlayerInfoDetails", a)
      )
    );
  } //end function

  /**
   * Use for logout
   * If _redirect empty, go index
   * @param _redirect
   */
  Logout(_redirect: string = "") {
    // remove user from local storage to log user out

    let origen = localStorage.getItem("urlOrigen");

    console.log("origen: " + origen);

    sessionStorage.clear();
    localStorage.removeItem("CurrentPlayer");
    this.dataServide.changeLeagueSelected([]);
    this.dataServide.changeLineSelectedList([]);

    if (origen != null && origen != "") window.location.href = origen;
    else {
      // Go Redirec Site
      let _origin: string =
        _redirect && _redirect != "" ? _redirect : window.location.origin;
      window.location.assign(_origin);
    }
  }
  private handleError<T>(operation = "operation", result?: T) {
    return (errorResponse: any): Observable<any> => {
      // const errorObj: any;
      // errorObj.status = errorResponse.status == null ? 0 : errorResponse.status;
      // if (errorResponse.error.errors != null && errorResponse.error.errors.detail) {
      //   errorObj.errorMessage = errorResponse.error.errors.detail;
      // }
      return of(errorResponse as any);
    };
  }

  //---END
}
