<router-outlet></router-outlet>

<div [hidden]="_showHomePageMessage" id="ImportantMessageContainer"
  style="position: fixed;  top: 150px;  right: 10px;  left: 10px;  text-align: center;  z-index: 9000;">

  <div class="alert alert-warning alert-dismissible fade show" role="alert"
    style="display: inline-block;max-width: 800px;">
    <strong>{{'homePage.homePageMessageHeader'|translate}}</strong> <br><br>
    <div id="homeMessagePage">
    </div>

    <button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

</div>

<!-- USE FOR LOAD COMPONENT SIGNUP - CASHIER -->
<app-quick-access *ngIf="_goQuickAccess"></app-quick-access>

<!-- USE FOR SET DATA GPRO REQUEST -->
<input type="hidden" id="csid" name="csid" class="csid" />