import { CompileWagerDto } from "./genericModels";

export class CompiledLineDetails {

    TeamDescription: string;
    TeamDescriptionEng: string;
    VersusTeamDescription: string;
    LineDescription: string;
    Description: string;
    DescriptionEng: string;
    IdSport: string;
    IdGameType: number;
    ParentGame: number;
    FamilyGame: number;
    IdEvent: number;
    EventDescription: string;
    OriginalPoints: number;
    OriginalOdds: number;
    Period: number;
    VisitorPitcher: string;
    HomePitcher: string;
    CanChoosePitcher: boolean;
    PointsPurchasedJuice: number;
    ChartRisk: number;
    ChartWin: number;
    IdGame: number;
    Play: number;
    Points: number;
    Odds: number;
    IsOnOpenBets: boolean;
    Pitcher: number;
    PointsPuchased: number;
    GameDateTime: string;
    IsKey: boolean;
    IsLineFromAgent: boolean;
    PitcherList: PitcherItem[];
    BuyPointsList: BuyPointItem[];
    WagerOptions: WagerOptions;
    NewLineDescription: string;
    NewLineOdds: number;
    MarkForDeletion: boolean;
    VersusParentDescription: string;
    IsDerivate: boolean;
    Type : string;
    Level : number;
    ODPoints : number;
    LineChanges : number;
    LineChangesError : number;
    LineChangesMsg :string;

}

export class CompiledDetails {

    WagerType: number;
    TicketNumber: string;
    TeaserCanBuyHalf: number;
    TeaserCanBuyOne: number;
    TeaserPointPurchased: number;
    WagerDescription: string;
    WagerDescriptionEng: string;
    Amount: number;
    Win: number;
    Risk: number;
    IDWT: number;
    RiskWin: number;
    RoundRobinCombination: string;
    CompacCombinations: string;
    FillIdWager: number;
    WagerTypeDesc: string;
    CompiledLineDetailsList: CompiledLineDetails[];
    UsrRisk: number;
    UsrWin: number;
    UsrPointsSelected: string = "0";
    UsrPitcherSelected: string = "0";
    TeaserOdds: TeaserOdds;
}

export class ListCompiledObjectList {
    Details: CompiledObject[];
    ErrorMsg: string;
    ErrorMsgKey: string;
    ErrorMsgParams: string;
    ErrorCode: string;
}


export class CompiledObject {

    CompiledDetailsList: CompiledDetails[];
    RoundRobinCombinations: RRCombinations[];
    ErrorMsg: string;
    ErrorMsgKey: string;
    ErrorMsgParams: string;
    FreePlayAvailable: number;
    IsConfirmed: boolean;
    UserFreePlayAvailable: boolean;
    SecurityCode: string;
    WagerTracker: number;
    LastWagerId: number;
    IfBetAskAmount: boolean;
    IdPlayer: number;
    IdCall: number;
    IdProfile: number;
    IdProfileLimits: number;
    IfBetRisk: number;
    IfBetWin: number;
    IdUser: number;
    Show: boolean = true;
    LineChanged: boolean;
}

export class RRCombinations {
    Value: number;
    CombiDescription: string;
    selected: boolean = false;
    Amount: number = 0;
    Win: number = 0;
    Risk: number = 0;
}

export class TeaserOdds {
    Odds: number;
    BuyHalf: number;
    BuyOne: number
}


/************************************* AUX MODELS ***********************************************/
/********************************************************************************************** */


// export class SpecialConfirmPostDto {
//     WagerType: number;
//     Compile: CompiledObject;
//     Confirm: CompiledObject;
//     Post: CompiledObject;
// }

export class MiscRRselection {
    Selection: string;
    Amount: number;
}

// export class MiscPointsPitcherSelection
// {
//     OptionParameter: number;
//     OptionType: number;
// }

export class SpecialSecondBet {
    SelectionList: MiscRRselection[];
    CompileWagerData: CompileWagerDto;
    LittleParlayRisk: number;
    UseFreePlay: boolean;
    //PointsPicherSel: MiscPointsPitcherSelection[];
}

export class SpecialFirstBet {
    CompiledObject: CompiledObject;
    ObjectToCompile: CompileWagerDto;
}

// export class RequestSpecialConfirmPostDto {


//     FirstBetData: SpecialFirstBet;
//     SecondBetData: SpecialSecondBet;
// }


export class PitcherItem {
    Value: number;
    StrOption: string;
}

export class PitcherArray {
    PitcherData: PitcherItem[];
}


export class WagerOptions {
    IdGame: number;
    OptionType: number;
    OptionParameter: number;
    Play: number;
}

export class AuthenticationDto {
    AccountName: string;
    Password: string;
    Token: string;
}

export class BuyPointItem {
    LineDesc: string;
    BuyPointsDesc: string;
    BuyPoints: string;
    BetSlipDesc: string;
}

export class BuyPointsArrayOfItemList {
    BuypointsItemList: BuyPointItem[];
}

export class ShowParlaySummaryTeams {

}

/**Get Teasers types model*/
export class TeaserWagerTypesModel {

    WagerType: number;
    IdProfile: number;
    NumTeams: number;
}

export class TeaserWagerTypeDto {
    IdWagerType: number;
    Description: string;
    NFLSite: boolean;
    NFLTotal: boolean;
    NBASite: boolean;
    NBATotal: boolean;
    CBBSite: boolean;
    CBBTotal: boolean;
    CFBSite: boolean;
    CFBTotal: boolean;
    MaxOpenSpots: number;
}


export class WagerTypeModel {
    WagerTypeId: number;
    translate: string;
    Name: string;
    Available: boolean;
}

export class ClassicWagerObject {
    LeaguesListSelected: string[];
    WagerTypeSelected: number;
}

export class RequestGetLinesDto {

    IdPlayer: number;
    LineStyle: string;
    LeagueList: string[];
    TodayEventSport: string;
    Gmt: number = -100;  //by default -100. DEFAULT DGS VALUE
    NextHour: boolean = false;
    Player: string;
}

export class SportLeaguesInfoModel {
    Sport: string;
    Order: number;
    Img: string;
    IdWebRow: number;
    Show: boolean = true;
    Leagues: ActiveLeaguesModel[];
}

export class ActiveLeaguesModel {
    IdLeague: number;
    LeagueDescription: string;
    IdSport: string;
    LeagueSelected: boolean;
    Show: Boolean = true;
}

export class FillOpenBetsDTO {
    ErrorCode: string;
    ErrorMsg: string;
    Player: string;
    Wagers: WagerFillDto[];
}


export class WagerFillDto {
    HeaderDesc: string;
    IdWager: number;
    IdwagerType: number;
    PhoneLine: number;
    PlacedDate: string;
    PlacedTime: string;
    RiskAmount: string;
    WinAmount: string;
    TicketNumber: number;
    WagerType: number;
    Details: CBetDetailsDto[];
}


export class CBetDetailsDto {
    DetailDesc: string;
    GameDate: string;
    GameTime: string;
    IdGame: number;
    IdSport: string;
    PitcherChange: boolean;
    ShortGame: boolean;

}

export class ActionByPlayerDto {

    IdSite: number;
    IdAgent: number;
    IdPlayer: number;
    TypeCompetition: number;
}


// INBOX MODELS


//INBOX

export class TypeIdentificationModel {
    Id: number;
    Description: string;
    Code: string;
}

export class InternalUsers {
    IdUser: number;
    UserEmail: string;
    UserCompleteName: string;
    IsActive: boolean;
}

export class TicketGeneral {
    IdTicket: number;
    TicketNumber: number;
    IdSite: number;
    IdPlayer: number;
    IdAgent: number;
    Subject: string;
    IdTypeMessage: number;
    IdStatus: number;
    IdClaim: number;
    IdentificationType: TypeIdentificationModel;
    Identification: number;
    IsAuthorized: boolean;
    Procedure: Procedure;
    Place: number;
    IdTicketMessage: number;
    Message: number;
    Sender: number;
    DateTransaction: number;

}

export class Ticket {
    IdTicket: number;
    TicketNumber: number;
    IdSite: number;
    IdPlayer: PlayerV2Dto;
    IdAgent: InternalUsers;
    Subject: string;
    IdType: number;
    IdStatus: MessageStatus;
    DateTransaction: Date;
}

export class TicketMessage {
    IdTicketMessage: number;
    IdTicket: number;
    Message: string;
    Sender: number;
    DateTransactionMessage: Date;
}

export class ClaimDetail {
    IdClaim: number;
    IdTicket: number;
    IdentificationType: number;
    Identification: number;
    IsAuthorized: number;
    Procedure: number;
    Place: number;
    Reason: string;
    DateTransactionMessage: Date;
    IdSender: number;
}

export class Message {
    Type: string;
    Payload: string;
}

export class City {
    name: string;
    code: string;
}


export class PlayerV2Dto {
    Player: string;
    IdPlayer: number;
    IdAgent: number;
    PlayerEmail: string;
    LanguageId: number;
    CurrencyId: number;
    Language: string;

}

export class AllPlayerDto {
    Player: string;
    IdPlayer: number;
    IdAgent: number;
    PlayerEmail: string;
    LanguageId: number
    CurrencyId: number
    Language: string;
    Name: string;
    LastName: string;
    Address: string;
    City: string;
    State: string;
    Country: string;
    Phone: string;
}


export class MessageStatus {
    Id: number;
    Description: string;
}

export class FormItem {
    Id: number;
    Description: string;
}

export class Form {
    IdForm: number;
    nameItem: string;
    IdFormItem: number;
    IdSite: number;
    TypeSite: number;
    IsActive: boolean;
}

export class SiteModel {
    Id: number;
    Description: string;
    Domain: string;

}


export class Procedure {
    IdProcedure: number;
    Description: string;
}

//INBOX MODELS END


export class PlayerParleyLimitsDetailsDto {

    IdPlayer: number;
    IdProfile: number;
    NumTeams: number;
    Odds: number;
    MaxRisk: number;
    MaxPayout: number;
    ExtraJuice: number;

    MaxOpenSpots: number;
    MaxDogLines: number;

    MaxMoneyLines: number;
    MaxTotalLines: number;
}



export class SiteSportsDto {
    IdMapSport: number;
    SportName: string;
    IconClass: string;
    Order: number;
    Show:boolean = false;
    OpenMenu:boolean = false;
    Countries: SiteCountriesDto[];
}

export class SiteCountriesDto {
    IdIsoCountry: number;
    CountryName: string;
    Order: number;
    Leagues: SiteMainLeaguesDto[];
}

export class SiteMainLeaguesDto {
    IdMainLeague: number;
    MainLeagueName: string;
    Order: number;
    Derivatives: SiteDerivativeDto[];
}

export class SiteDerivativeDto {
    IdDgs: number;
    Derivative: string;
    Order: number
}
