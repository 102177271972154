import { Routes, RouterModule } from "@angular/router";
import { ExtLoginComponent } from "./FrontEnd/ext-login/ext-login.component";
import { ModuleWithProviders } from "@angular/core";

const appRoutes: Routes = [
  {
    path: "league-map",
    loadChildren: () =>
      import("./CoreWager/BetSlip/leagues-map/leagues-map.module").then(
        (c) => c.LeaguesMapModule
      ),
  }, // Use for view Id League

  { path: "trivia", redirectTo: "home/trivia" },
  { path: "extlogin", component: ExtLoginComponent },

  /*************************************** FRONTEND *************************************** */
  /*************************************** FRONTEND *************************************** */

  {
    path: "home",
    loadChildren: () =>
      import("./FrontEnd/master-page/master-page.module").then(
        (c) => c.HomePageModule
      ),
  },
  {
    path: "Inicio",
    loadChildren: () =>
      import("./FrontEnd/master-page/master-page.module").then(
        (c) => c.HomePageModule
      ),
  },
  /*************************************** BACKEND *************************************** */
  /*************************************** BACKEND *************************************** */

  {
    path: "mainbk",
    loadChildren: () =>
      import("./CoreWager/bknmasterpage/bknmasterpage.module").then(
        (c) => c.MainbackendModule
      ),
  },
  {
    path: "principal",
    loadChildren: () =>
      import("./CoreWager/bknmasterpage/bknmasterpage.module").then(
        (c) => c.MainbackendModule
      ),
  },

  {
    path: "**",
    redirectTo: "home/landing",
    pathMatch: "full",
  } /* Automatic redirect */,
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(
  appRoutes
);
