import { PlayerTransferDto } from "./user";
import { GameDto, GameByDeviceDto } from "./Bonuses/bonuseModels";

export class ConfigSite {
  WebApiUrl: string;
  CashierApiUrl: string;
  IdSite: number;
  DomainName: string;
  RacebookUrl: string;
  LiveDealerUrl: string;
  LiveWagerUrl: string;
  ConceptUrl: string;
  EvolutionGamingUrl: string;
  DigitalSportUrl: number;
  DigitalSportWidgetUrl: string;

  BetSoftUrl: string;
  NucleusUrl: string;

  PlatinumUrl: string;
  LocationServiceUrl: string;

  BetConnectionSportsUrl: string;

  TimeDelayCasino: number;

  CasinoConceptId: number;
  CasionoBetSoftId: number;
  CasinoNucleusId: number;

  CasinoEvolutionGamingId: number;
  CasinoGrooveGamingId: number;

  LinesUpdateSeconds: number;
  AllowAsianHandiCapOnParlays: boolean;
  DefaultUser: string;
  DefaultPass: string;
  DefaultHostName: string;
  ImgServerPath: string;
  ImgServerCashierPath: string;
  IconsPath: string;
  WebConfig: WebConfigDto[];
  CashierUrl: string;

  BetSoftUrlCashBonus: string;
  BetSoftUrlRoundsBonus: string;
  NucleusUrlCashBonus: string;
  NucleusUrlRoundsBonus: string;
  CasinoEndorphinaId: number;
  EndorphinaUrl: number;

  ProductPooltId: number;
  ProductSquaresId: number;
  ProductParleyCardsId: number;
  ProductSuicidePoolId: number;
  ProductSquaresUrl: string;
  ProductParleyCardsUrl: string;
  ProductPooltUrl: string;
  ProductSuicidePoolUrl: string;

  RealTimeESportsUrl: string;
  RealTimeESportsHubName: string;

  BetconnectionsHorsesUrl: string;
  MercadoPagoAccess: string;
  PatagoniaId: number;
  PatagoniaUrl: string;

  VIGId: number;
  VIGUrl: string;

  VenHorsesId: number;
  VenHorsesUrl: string;
  BetGamesId: number;
  BetGames_ProductionServer: string;
  BetGames_PartnerCode: string;
  url_to_betgames_iframe: string;

  XpressGamingId: number;
  XpressGamingUrl: string;
  ErrorLoadProductUrl: string;

  VirtualSports: string;
  VirtualSportsDGS: VirtualSportsDGS[];
  BrontoApiUrl: string;
}

export class VirtualSportsDGS {
  Title: string;
  SportId: string;
  LeagueId: number;
  Icon: string;
}

export class DomainConfigDto {
  Description: string;
  Domains: DomainConfigDetailsDto[];
}

export class DomainConfigDetailsDto {
  Id: number;
  Domain: string;
  Name: string;
}

export class ConfigUpdateInfoDto {
  Data: ConfigInfoDetailsDto[];
}

export class TokenRequestDTO {
  param1: string;
  param2: string;
}

export class ConfigInfoDetailsDto {
  Site: string;
  Name: string;
  Pass: string;
}

export class IsoCodeCountryModel {
  countries: CodeCountryModel[];
}

export class CodeCountryModel {
  code: string;
  name: string;
  IsoContry: string;
}

export class WebConfigDto {
  constructor(initial?: any) {
    initial = initial || {};
    this.UserSportMenuV20 = initial.UserSportMenuV20;
  }
  Name: string;
  ImagesPath: string;
  MenuOptions: string[];
  phone: string;
  netellerCode: string;
  ppFormEmail: string;
  ppFormURL: string;
  IdSite: string;
  IdAgent: number;
  Language: string;
  LandingImages: Array<any> = [];
  ExteriorLineStyle: string;
  TodayEventSport: string;
  BackEndLandingPage: string;
  LandingPage: string;
  GuestPlayer: string;
  UseSoc1x2: boolean;
  LiveStreamingTheme: string;
  ImgLobbyCasino: string;
  DigitalSportTheme: string;
  CashierUrl: string;
  ExternalLanding: string;
  RedirectOrigen: string;
  UseLobby: number;
  LiveWagerGoldTheme: string;
  UserSportMenuV20: boolean = false;
}

export class LineCompile {
  IdGame: number;
  Play: number;
  Points: number;
  Odds: number;
  Pitcher: number;
  IsOnOpenBets: boolean;
  PointsPurchased: number;
  IsSpread: boolean;
  IsTotal: boolean;
  Rotation: number;
  Selection: string;
  Team: string;
  IsTnt: boolean;
  LineValue: string;
  LineOdds: string;
  LinePoints: string;
  IdSport: string;
  Amount: number;
  WinAmount: number;
}

export class CompileWagerDto {
  Details: LineCompile[];
  IdPlayer: number;
  IdCall: string;
  WagerType: number;
  OpenSpots: number;
  IdWagerType: number;
  Player: string;
}

export class Ticket {
  TicketNumber: string;
  TicketDetail: string;
  TicketWin: number;
  TicketRisk: number;
}

export class PlayerStatiscticsModel {
  IdPlayer: number;
  CurrentBalance: number;
  AmountAtRisk: number;
  BonusPoints: number;
  AvailBalance: number;
}

export class OpenBetItem {
  TicketNumber: number;
  GameDate: string;
  DatePlaced: string;
  BetDescription: string;
  User: string;
  Risk: number;
  Win: number;
  Phone: string;
  SportDescription: OpenBetDescriptionItem[];
  Details: OpenBetDetail[];
  Result: number;
}

export class OpenBetDetail {
  Detail: string;
  Sport: string;
  Result: number;
}

export class OpenBetDescriptionItem {
  IdSport: string;
  Description: string;
}

export class TimeZoneItem {
  IdTimeZone: number;
  TimeZoneDesc: string;
}

export class RequestTimeZones {}

export class RequestPlayerInfoDto {
  PlayerInfo: PlayerTransferDto;
}

export class RequestLiveToken {
  IdPlayer: number;
  IpAddress: string;
}

export class RequestPlayerHistory {
  IdPlayer: number;
  Week: number;
  IdSport: string;
  IdCall: number;
}

export class PH_details {
  DetailResult: string;
  IdGame: number;
  DetailDesc: string;
  GameDate: string;
  GameTime: string;
  IdSport: string;
  ShortGame: string;
  PitcherChange: string;
}

export class PH_wager {
  WagerOrTrans: string;
  Result: string;
  TransType: string;
  IfBetWagerType: string;
  WinLoss: string;
  Balance: string;
  HeaderDesc: string;
  TicketNumber: string;
  IdWager: number;
  PlacedDate: string;
  PlacedTime: string;
  WagerType: string;
  UserName: string;
  PhoneLine: string;
  RiskAmount: string;
  WinAmount: string;
  IdWagerType: number;
  Details: PH_details[];
}

export class PH_dayDetails {
  WinLossAmount: string;
  Balance: string;
  CashInOut: string;
  Dates: string;
  ShowThis: Boolean;
  Wagers: PH_wager[];
}

export class PlayerHistoryDto {
  Day1: number;
  Day2: number;
  Day3: number;
  Day4: number;
  Day5: number;
  Day6: number;
  Day7: number;
  Player: string;
  StartDate: string;
  EndDate: string;
  Cash0: string;
  Cash1: string;
  Cash2: string;
  Cash3: string;
  Cash4: string;
  Cash5: string;
  Cash6: string;
  Cash7: string;
  Cash8: string;
  WonLost1: string;
  WonLost2: string;
  WonLost3: string;
  WonLost4: string;
  WonLost5: string;
  WonLost6: string;
  WonLost7: string;
  WonLost8: string;
  Bal0: string;
  Bal1: string;
  Bal2: string;
  Bal3: string;
  Bal4: string;
  Bal5: string;
  Bal6: string;
  Bal7: string;
  Bal8: string;
  Details: PH_dayDetails[];
}

export class CashierTransDTO {
  clientid: number;
  customerid: number;
  amount: number;
  coin: string;
}

export class CashierGetTransDTO {
  clientid: number;
  customerid: number;
}

export class RequestUpdatePlayerPitcher {
  IdPlayer: number;
  PlayerPitcher: number;
}

export class RequestUpdatePlayerOptionInitDefault {
  IdPlayer: number;
  Routedefault: string;
}

export class RequestSiteVarCashierDto {
  CountryCode: string;
  IdSite: number;
  IdCurrency: number;
  IdPaymentMethod: number;
  IdPlayer: number;
}

export class SiteVarCashierDto {
  AccessCredit: boolean;
  AccessBitcoin: boolean;
  AccessMoneygram: boolean;
  AccessWU: boolean;
  AccessSkrill: boolean;
  AccessBankwire: boolean;
  AccessAstropay: boolean;
  AccessAstropayDirect: boolean;
  AccessRia: boolean;
  AccessSafetypay: boolean;
  AccessPayco: boolean;
  Access123Pago: boolean;
}

export class CountryDto {
  CountryCode: string;
  Country: string;
}

export class StateDto {
  StateCode: string;
  State: string;
}

export class ChatSiteModel {
  IdSite: number;
  Description: string;
  ActiveChat: boolean;
  key: string;
  ActiveLiveChat: boolean;
  LiveChatURL: string;
}

export class ActionPlayersModel {
  DateInit: Date;
  DateEnd: Date;

  PlayerPosition: Action_PlayerModel;
  Positions: Action_PlayerModel[];
}

export class Action_PlayerModel {
  IdPlayer: number;
  Position: number;
  Player: string;
  Description: string;
  Name: string;
  City: string;
  Total: number;
}

export class CasinoTomHornModel {
  Url: string;
  Params: string;
}

export class CasinosModel {
  SessionId: string;
  Products: CasinoProductsModel[];
}

export class CasinoProductsModel {
  GameId: number;
  CasinoId: number;
  CodeGame: number;
  BankId: number;
  deviceTypeId: number;
  NameGame: string;
  Params: string;
  TabName: string;
  Language: string;
  ImageUrl: string;
}

export class CasinoInfoModel {
  Currency: string;

  WeeklyBalance: number;
  WeeklyMaxWin: number;
  WeeklyMaxLoss: number;

  DailyBalance: number;
  DailyMaxWin: number;
  DailyMaxLoss: number;
}

export class DateReportModel {
  DateInit: string;
  DateEnd: string;
  MonthInit: string;
  MonthEnd: string;
}

export class SiteMessagesDto {
  message: string;
  displayuntil: Date;
}

export class QuickAccessDto {
  Title: string;
  Step: number;
  View: boolean;
}

export class RequestBanksProvidersDto {
  public SiteId: number;
  public ProductId: number;
}

export class ReponseBanksProvidersDto {
  public BetsoftBankId: string;
  public NucleusBankId: string;
  public Token: string;
}

export class RequestEndorphinaDto {
  public SiteId: number;
  public ProductId: number;
  public GameByDeviceId: number;
}

export class RequestSoccerUltraDto {
  public SiteId: string;
}

export class ResponseEndorphinaDto {
  public Exit: string;
  public NodeId: string;
  public Profile: string;
  public Token: string;
  public Sing: string;
}

export class ResponseSuicidePoolAccess {
  public poolId: number;
  public error: string;
}

export class ProductsService {
  public src: string;
  public productId: number;
  public name: string;
  public url: string;
}

export class DigitalSportEventsDto {
  IdEvent: number;
  Status: string;
  Description: string;
}

export class PlayForFreeResponse {
  public FrameUrl: string;
}

export class PlayForFreeRequest {
  public ProductId: number;
  public SiteId: number;
  public GameId: number;
  public GameByDeviceId: number;
  public DeviceId: number;
}

//Generic object for generate all tokens on api with URL providers.
//Change in progress.......
export class InitProductResponse {
  public Url: string;
  public Token: string;
}

export class InitProductRequest {
  public ProductId: number;
  public SiteId: number;
  public DeviceId: number;
  public ParentGame: GameDto;
  public ChildGame: GameByDeviceDto;
}

export class VirtualSportTeamRequestDto {
  public SportId: string;
}

export class VirtualSportTeamDto {
  public Name: string;
  public TeamStatisticId: number;
  public LeagueId: number;
  public LeagueName: string;
  public SportId: string;
  public SportName: string;
  public ConferenceId: number;
  public ConferenceName: string;
  public Description: string;
  public GamesPlayed: number;
  public GamesWon: number;
  public GamesLost: number;
  public GamesTied: number;
  public Streak: number;
  public PointsAverage: number;
  public PointsAverageAgainst: number;
  public Position: number;
}

export class ScheduleGameRequestDto {
  public SportId: string;
  public LeagueId: number;
  public Date: string;
}

export class ScheduleGameResponseDto {
  public RepetitionUrl: string;
  public GameDate: Date;
  public Graded: boolean;
  public GameStat: string;
  public VisitorScore: number;
  public VisitorTeam: string;
  public HomeTeam: string;
  public HomeScore: number;
  public ChildrenGames: ScheduleGameResponseDto[];
}
