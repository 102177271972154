import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { PlayerDto, AccessSite, PlayerTransferDto } from '../models/user';
import { LineCompile, Ticket, ProductsService, ChatSiteModel } from '../models/genericModels'


@Injectable()
export class DataService {

    constructor(private _Translate: TranslateService) { }

    _ListOfLinesSelectedByPlayer: LineCompile[] = [];
    //  Leagues -> Lines

    //create a data service.
    private _LeagueSelected = new BehaviorSubject<any>("nodata");

    //use this to get data stored in dataservice.
    currentLeagueSelected = this._LeagueSelected.asObservable();


    // Lines -> BetCar

    // see internal documentation before fro details.
    private _LineSelectedList = new BehaviorSubject<LineCompile[]>([]);
    CurrentLineSelectedList = this._LineSelectedList.asObservable();


    // Update Balance
    private _UpdateBalance = new BehaviorSubject<any>("nodata");
    UpdateBalance = this._UpdateBalance.asObservable();


    // Update user in session
    private _UserInSession = new BehaviorSubject<PlayerDto>(null);
    UserSession = this._UserInSession.asObservable();

    // Update Access site
    private _AccessSite = new BehaviorSubject<AccessSite>(null);
    AccessSite = this._AccessSite.asObservable();

    // // ClassicView WagerSelected
    // private _CVWagerSelected = new BehaviorSubject<number>(null);
    // CVWagerSelected = this._CVWagerSelected.asObservable();

    // see internal documentation before fro details.
    private _CVLineSelectedList = new BehaviorSubject<LineCompile[]>([]);
    CVCurrentLineSelectedList = this._CVLineSelectedList.asObservable();

    private _CurrentBets = new BehaviorSubject<Ticket[]>(null);
    CurrentBets = this._CurrentBets.asObservable();

    private _LivesWagerTotal = new BehaviorSubject<number>(null);
    LivesWagerTotal = this._LivesWagerTotal.asObservable();

    private goForceUpdatePlayer = new BehaviorSubject<boolean>(false); // Use for view Quick SignUp
    _goForceUpdatePlayer = this.goForceUpdatePlayer.asObservable();

    private _Language = new BehaviorSubject<string>("en");
    Language = this._Language.asObservable();

    private _PlayerInfo = new BehaviorSubject<PlayerTransferDto>(null);
    PlayerInfo = this._PlayerInfo.asObservable();

    private _chat = new BehaviorSubject<ChatSiteModel>(null);
    Chat = this._chat.asObservable();

    private _LeagueToggleMenu = new BehaviorSubject<boolean>(false);
    leagueToggleMenu = this._LeagueToggleMenu.asObservable();

    private _CarBsSelections = new BehaviorSubject<boolean>(null);
    carBsSelections = this._CarBsSelections.asObservable();

    changeleagueMenuToggle(message: boolean)
    {
        this._LeagueToggleMenu.next(message);
    }

    changeCarBsToggle(message: boolean)
    {
        this._CarBsSelections.next(message);
    }

    changeLanguage(request: string) { this._Translate.use(request); this._Language.next(request); }  // Action Menu


    // use this method to change values in our variable.
    changeLeagueSelected(LeagueData: string[]) {
        // console.log("data1");
        // console.log(LeagueData);
        this._LeagueSelected.next(LeagueData)
        // console.log("data2");
        // console.log(this._LeagueSelected);
    }

    changeLineSelectedList(LineSelectedList: LineCompile[]) {
        this._LineSelectedList.next(LineSelectedList);
    }


    changeUpdateBalanceStatus(UpdateBalance: boolean) {
        this._UpdateBalance.next(UpdateBalance);
    }

    changeDataUserSession(UserSession: PlayerDto) {
        this._UserInSession.next(UserSession);
    }

    changeDataAccessSite(AccessSite: AccessSite) {
        this._AccessSite.next(AccessSite);
    }

    // changeCVWagerSelected(WagerSelected: number) {
    //     this._CVWagerSelected.next(WagerSelected);
    // }

    changeCVLineSelectedList(LineSelectedList: LineCompile[]) {
        this._CVLineSelectedList.next(LineSelectedList);
    }

    changeUpdateCurrentBets(bets: Ticket[]) {
        this._CurrentBets.next(bets);
    }


    changeForceUpdatePlayer(t: boolean) {
        this.goForceUpdatePlayer.next(t);
    }


    /***************************** QUCIK ACCESS **********************************/
    /***************************** QUCIK ACCESS **********************************/


    // Change status of Modal = > For enable o disable modal of Quick Access
    private goQuickAccess = new BehaviorSubject<boolean>(false); // Use for view Quick SignUp
    _goQuickAccess = this.goQuickAccess.asObservable();
    changeActionModalQuickAccess(t: boolean) { this.goQuickAccess.next(t); }

    // Change status of Modal = > For enable o disable modal of Quick Access
    private quickAccessTitle = new BehaviorSubject<string>(""); // Use for Quick access Title
    _quickAccessTitle = this.quickAccessTitle.asObservable();
    changeTitleQuickAccess(t: string) { this.quickAccessTitle.next(t); }

    // Change status of Modal = > For enable o disable modal of Quick Access
    private goQuickStep = new BehaviorSubject<number>(0); // Use for view Quick SignUp
    _goQuickStep = this.goQuickStep.asObservable();
    changeStepQuickAccess(t: number) { this.goQuickStep.next(t); }

    /***************************** QUCIK ACCESS **********************************/
    /***************************** QUCIK ACCESS **********************************/


    /***************************** PRODUCTS CONTEST **********************************/
    /***************************** PRODUCTS CONTEST **********************************/

    private productId = new BehaviorSubject<ProductsService>(null); // Use for view Quick SignUp
    _productId = this.productId.asObservable();
    changeProductAccess(t: ProductsService) { this.productId.next(t); }

    changePlayerInfo(t: PlayerTransferDto) { this._PlayerInfo.next(t); }

    setChat(t: ChatSiteModel) { this._chat.next(t) }
    
    

    //---END
}
